<div *ngIf="!infoPage" class="create-token-container">
    <div class="header-con">
        <h3 class="section-title">
            Create Token
        </h3>
    
        <ul>
            <li>
                Minting a token requires Wallet approval and GAS fees.
            </li>
        
            <li>
                Please review the MetaMask wallet popup and click "confirm."
            </li>
        
            <li>
                It may take up to 1 minute for the token to be minted.
            </li>
        </ul>
    </div>
    
    <div class="type-con">
        <h5 class="section-title">
            Choose wallet type
        </h5>
    
        <div class="type-cards">
            <p-card styleClass="wallet-card"
                header="Client Side / Self-Custody Wallet"
                [class.selected]="selectedOption === 'client-self'"
                (click)="setOption('client-self')"
            >
                <ng-template pTemplate="header">
                    <img src="assets/images/placeholder-rectangle.png" class="card-img">
                </ng-template>
    
                <p>
                    Pops open the user wallet (ex: metamask) to pay gas fee and mint the NFT
                </p>

                <i *ngIf="selectedOption === 'client-self'"
                    class="mdi mdi-check-circle selection-mark"
                ></i>
            </p-card>
    
            <p-card styleClass="wallet-card"
                header="Server Side / Self-Custody Wallet"
                [class.selected]="selectedOption === 'server-self'"
                (click)="setOption('server-self')"
            >
                <ng-template pTemplate="header">
                    <img src="assets/images/placeholder-rectangle.png" class="card-img">
                </ng-template>
    
                <p>
                    Server will mint and transfer the NFT to the below self custody wallet address
                </p>

                <i *ngIf="selectedOption === 'server-self'"
                    class="mdi mdi-check-circle selection-mark"
                ></i>
            </p-card>
    
            <p-card styleClass="wallet-card"
                header="Server Side / Custodian Wallet"
                [class.selected]="selectedOption === 'custodian'"
                (click)="setOption('custodian')"
            >
                <ng-template pTemplate="header">
                    <img src="assets/images/placeholder-rectangle.png" class="card-img">
                </ng-template>
    
                <p>
                    Server will create a custodian wallet, mint and transfer the NFT to the custodian wallet
                </p>

                <i *ngIf="selectedOption === 'custodian'"
                    class="mdi mdi-check-circle selection-mark"
                ></i>
            </p-card>
        </div>
    </div>

    <div *ngIf="selectedOption === 'server-self'" class="address-con">
        <h5 class="section-title">
            Enter wallet address
        </h5>

        <input type="text" pInputText [(ngModel)]="manualAddress"/>
    </div>

    <div class="next-con">
        <p-button label="Next"
            [disabled]="!selectedOption"
            (click)="displayInfoForm()"
        ></p-button>
    </div>
</div>

<div *ngIf="infoPage" class="token-info-con">
    <div class="header-con">
        <h3>Create a Token</h3>

        <div class="subheader">
            Select the type of token you would like to create or <a routerLink="/demos">View Demos</a> for detailed examples.
        </div>
    </div>

    <form [formGroup]="infoForm">
        <div class="type-selector-con">
            <div *ngFor="let type of typeOptions" class="type-option" (click)="selectType(type.value)">
                <div class="img-con">
                    <img [src]="type.image">
                </div>

                {{ type.label }}

                <div class="radio-con">
                    <p-radioButton
                        [inputId]="type.value"
                        [value]="type.value"
                        formControlName="nft_type"
                    ></p-radioButton>
                </div>
            </div>
        </div>

        <div class="form-header">
            <div class="header-title">
                {{ typeTitle(infoForm.value['nft_type']) }}
            </div>

            <div class="populate-btn">
                <button pButton
                    label="Auto Populate"
                    class="p-button-link"
                    (click)="autoPopulateInfo()"
                ></button>
            </div>
        </div>

        <div *ngFor="let field of formSchema" class="form-row">
            <ng-container *ngIf="!field.conditional
                || (infoForm.value[field.conditional.key] === field.conditional.value)"
            >
                <label *ngIf="field.label">
                    {{ field.label }}
                </label>

                <input *ngIf="field.type === 'text'"
                    pInputText type="text"
                    [formControlName]="field.key"
                    [placeholder]="field.placeholder || field.label"
                />
    
                <input *ngIf="field.type === 'date'"
                    pInputText type="text"
                    [formControlName]="field.key"
                    placeholder="MM/DD/YYYY"
                />
    
                <p-dropdown *ngIf="field.type === 'select'"
                    appendTo="body"
                    [formControlName]="field.key"
                    [placeholder]="field.placeholder || field.label"
                    [options]="field.options"
                ></p-dropdown>
    
                <div *ngIf="field.type === 'file'"
                    class="file-attach-dropzone"
                >
                <button type="button" (click)="fileInput.click()">
                    <span>Upload</span>
                    <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
                  </button>
                  <span *ngIf="fileName">{{fileName}}</span>
                </div>
            </ng-container>
        </div>
    </form>

    <div class="next-con">
        <p-button label="Next"
            (click)="createToken()"
        ></p-button>

        <p-button label="Back" (click)="infoPage = false"></p-button>
    </div>
</div>