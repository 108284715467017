import { createReducer, on } from "@ngrx/store";
import * as DataVaultActions from "./data-vault.actions";


export interface PdfMetadataModel {
    hash: string;
    encoding?: string;
    md5: string;
    mimetype?: string;
    size: number;
}

export interface DataVaultState {
    pdfHash: string;
    tokenId: string;
    data: any[];
}

const initialState: DataVaultState = <DataVaultState>{
    pdfHash: null,
    tokenId: null,
    data: []
};

export const dataVaultReducer = createReducer(
    initialState,
    on(DataVaultActions.SetPdfTokenIdAction, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload
        });
    }),
    on(DataVaultActions.PdfMintSuccess, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload.events.Transfer.returnValues.tokenId
        });
    }),
    on(DataVaultActions.AddStageAction, (state, action) => {
        return Object.assign({}, state, {
            bottles: state.data.map(gem => {
                if (gem.tokenId === action.payload.tokenId) {
                    let stages = gem.stages || [];
                    stages.push(action.payload);

                    return Object.assign({}, gem, { stages: stages });

                } else {
                    return gem;
                }
            })
        })
    })
);
