export interface RewardsCardModel {
    tokenId: string;
    link?: string;
    name: string;
    description?: string;
    merchant_name: string;
    customer_name: string;
    customer_dob: string;
    customer_phone?: string;
    member_since?: string;
}

export interface EarnItemModel {
    name: string;
    image: string;
    price: string;
    points: number;
}

export interface RedeemItemModel {
    name: string;
    image: string;
    price: string;
    points: number;
    type: string;
    summary?: string;
    summaryShort?: string;
    policy?: string;
    hash?: string;
}