<div class="redeem-rewards-page">
    <div class="page-title">
        Redeem Rewards
    </div>

    <div class="reward" *ngFor="let reward of redeemItems">
        <div class="left-reward">
            <img class="reward-img" [src]="reward.image">

            <div class="reward-name">
                {{ reward.name }}
            </div>

            <div class="reward-cost">
                <span class="line">
                    Value: {{ reward.price }}
                </span>

                <span class="line">
                    Reward Points {{ reward.points }}
                </span>
            </div>
        </div>

        <div class="right-reward">
            <div class="right-text-con">
                <div class="reward-desc">
                    {{ reward.summary }}
                </div>
    
                <div *ngIf="reward.policy" class="policy">
                    Policy {{ reward.policy }}
                </div>
            </div>

            <p-button
                label="Redeem & Mint"
                styleClass="redeemBtn"
                (onClick)="redeemReward(reward)"
            ></p-button>
        </div>
    </div>
</div>