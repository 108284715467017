import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { BaseService } from "../../shared/services";
import { INFURA_BASE_URL } from "../../shared/utilities/constants";
import { EarnItemModel, RedeemItemModel } from "../../shared/models";


@Injectable()
export class CustomerLoyaltyService extends BaseService {
    constructor(
        http: HttpClient
    ) {
        super(http);
    }

    uploadMetadata(data: any): Observable<any> {
        return this.makeRequest({
            method: BaseService.POST,
            url: `api/whiskey/metadata`,
            body: Object.assign({}, data, {
                nft_type: "LOYALTY",
                image: ""
            })
        });
    }

    mintCard(uri: string): Observable<any> {
        let fullUri = INFURA_BASE_URL + uri;

        return this.makeRequest({
            method: BaseService.POST,
            url: `api/tickets/safemint`,
            body: {
                walletId: "",
                tokenUri: fullUri
            }
        });
    }

    earnReward(
        rewardsCardId: string,
        item: EarnItemModel
    ): Observable<any> {
        return this.makeRequest({
            url: "api/supplychain/trackit",
            method: BaseService.POST,
            body: Object.assign({}, item, {
                tokenId: rewardsCardId,
                rewards_action: "EARN",
                stage: "EARN"
            })
        });
    }

    redeemReward(
        rewardsCardId: string,
        item: RedeemItemModel
    ): Observable<any> {
        return this.makeRequest({
            url: "api/supplychain/trackit",
            method: BaseService.POST,
            body: Object.assign({}, item, {
                tokenId: rewardsCardId,
                rewards_action: "BURN",
                stage: "BURN"
            })
        });
    }

    trackRewardsInfo(tokenId: string): Observable<any> {
        return this.makeRequest({
            url: `api/supplychain/getTrackItInfo?tokenId=${tokenId}`,
            method: BaseService.GET
        });
    }
}