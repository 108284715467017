<!-- Loading... -->
<div class="page-body">
    <p>
        Please Login
    </p>
    
    <p-button
        label="Login"
        (click)="attemptLogin()"
    ></p-button>
</div>
