import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription, firstValueFrom } from "rxjs";

import { AppStore } from "../../../store";
import { EarnItemModel, RewardsCardModel } from "../../../shared/models";
import { EmitAlertAction } from "../../../shared/components/alerts";
import { ShowLoadingMaskAction, HideLoadingMaskAction } from "../../../layout/layout.actions";
import { getRewardsCard } from "../customer-loyalty.selectors";
import { CustomerLoyaltyService } from "../customer-loyalty.service";
import { AdjustPointsAction } from "../customer-loyalty.actions";


@Component({
    selector: "earn-rewards",
    templateUrl: "./earn-rewards.component.html",
    styleUrls: ["./earn-rewards.component.scss"]
})
export class EarnRewardsComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private rewardsCard: RewardsCardModel;

    public earnItems: EarnItemModel[] = [];

    constructor(
        private store: Store<AppStore>,
        private service: CustomerLoyaltyService
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(getRewardsCard).subscribe(card => {
                this.rewardsCard = card;
            })
        );

        this.earnItems = [
            {
                "name": "Samsung Galaxy Z Flip 3",
                "image": "assets/images/phones/phone-1.png",
                "price": "$400",
                "points": 100
            },
            {
                "name": "Samsung Galaxy s21",
                "image": "assets/images/phones/phone-2.png",
                "price": "$600",
                "points": 150
            },
            {
                "name": "iPhone 14",
                "image": "assets/images/phones/phone-3.png",
                "price": "$1000",
                "points": 300
            }
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    earnPoints(item: EarnItemModel) {
        if (!this.rewardsCard || !this.rewardsCard.tokenId) {
            this.store.dispatch(EmitAlertAction({
                message: "Please mint a Rewards Card first.",
                alert_type: "info"
            }));

            return;
        }

        this.store.dispatch(ShowLoadingMaskAction());

        firstValueFrom(
            this.service.earnReward(
                this.rewardsCard.tokenId,
                item
            )
        ).then(response => {
            this.store.dispatch(HideLoadingMaskAction());

            this.store.dispatch(AdjustPointsAction(item.points));

            this.store.dispatch(EmitAlertAction({
                message: "Earn rewards successfull!",
                alert_type: "success"
            }));

        }).catch(err => {
            console.log("Error earning reward:", err);

            this.store.dispatch(HideLoadingMaskAction());
            
            this.store.dispatch(EmitAlertAction({
                message: "There was an error earning your reward. Please try again later.",
                alert_type: "error"
            }));
        });
    }
}