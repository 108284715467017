import { createAction } from "@ngrx/store";

export const DiplomaActionTypes = {
    SET_TOKENID: "[Diploma] Set TokenId",
    PDF_MINT: "[Diploma] Mint PDF",
    PDF_MINT_SUCCESS: "[Diploma] Mint PDF Success",
    PDF_MINT_FAILURE: "[Diploma] Mint PDF Failure",
    PDF_VIEW: "[Diploma] View PDF",
    PDF_VIEW_SUCCESS: "[Diploma] View PDF Success",
    PDF_VIEW_FAILURE: "[Diploma] View PDF Failure"
}

export const SetPdfTokenIdAction = createAction(
    DiplomaActionTypes.SET_TOKENID,
    (id: string) => ({ payload: id })
);

export const PdfMint = createAction(
    DiplomaActionTypes.PDF_MINT,
    (data: any) => ({ payload: data })
);

export const PdfMintSuccess = createAction(
    DiplomaActionTypes.PDF_MINT_SUCCESS,
    (res: any) => ({ payload: res })
);

export const PdfMintFail = createAction(
    DiplomaActionTypes.PDF_MINT_FAILURE,
    (err: any) => ({ payload: err })
);

export const viewPdf = createAction(
    DiplomaActionTypes.PDF_VIEW,
    (tokenId: string) => ({ tokenId: tokenId })
);

export const viewPdfSuccess = createAction(
    DiplomaActionTypes.PDF_VIEW_SUCCESS,
    (data: any) => ({payload:data})
);

export const viewPdfFail = createAction(
    DiplomaActionTypes.PDF_VIEW_FAILURE,
    (err: any) => ({payload:err})
);
