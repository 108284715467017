import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";


@Component({
    selector: "app-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"]
})
export class LoginPageComponent implements OnInit {
    constructor(
        private oauthService: OAuthService,
        private router: Router
    ) {}

    ngOnInit() {
        // this.initLoginFlow();
    }

    private initLoginFlow() {
        this.oauthService
            .loadDiscoveryDocumentAndTryLogin()
            .then(response => {
                const hasAccessToken = this.oauthService.hasValidAccessToken();
                const hasIdToken = this.oauthService.hasValidIdToken();

                if (hasIdToken || hasAccessToken) {
                    this.router.navigate(["wallet"]);

                } else {
                    this.oauthService.initLoginFlow();
                }

            }).catch(error => {
                console.log("Login error:", error);
                if (window.location.pathname.split("/").pop() !== "") {
                    // Redirect user to url of login page (hosted)
                    window.location.href = window.location.origin;
                }
            });
    }

    attemptLogin() {
        this.initLoginFlow();
    }
}