import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { Subscription } from "rxjs";
import detectEthereumProvider from '@metamask/detect-provider';

import { AppStore } from "../../../store";
import { loginUserAction } from '../../../user/user.actions';
import { getUserBalance, getUserName } from '../../../user/user.selectors';
import { EmitAlertAction } from '../alerts/alerts.actions';


@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    public loginFormVisible: boolean = false;
    public loginForm: FormGroup;
    public userName: string;
    public userBalance: number; 

    constructor(
        private store: Store<AppStore>,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            username: new FormControl("")
        });

        this.subscriptions.push(
            this.store.pipe(select(getUserName)).subscribe(username => {
                this.userName = username;
            })
        );

        this.subscriptions.push(
            this.store.pipe(select(getUserBalance)).subscribe(balance => {
                this.userBalance = balance;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    showLoginForm() {
        this.loginFormVisible = true;
    }

    async loginUser() {
        try {
            let detectMetaMask = await detectEthereumProvider();

            if (detectMetaMask && detectMetaMask.isMetaMask) {
                detectMetaMask.on('accountsChanged', accounts => {
                    // TODO Handle account change
                    console.log("Accounts:", accounts);
                });

                this.store.dispatch(loginUserAction());

            } else {
                this.store.dispatch(EmitAlertAction({
                    message: "Metamask undetected. Please install metamask plugin in order to login",
                    alert_type: "error"
                }));
            }

        } catch(undetectedMetaMask) {
            this.store.dispatch(EmitAlertAction({
                message: "Error on login attempt. Please check to make sure metamask is installed",
                alert_type: "error"
            }));
        }
    }
}