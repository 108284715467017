import { createReducer, on } from "@ngrx/store";

import * as AlertActions from './alerts.actions';

export interface AlertState {
    alerts: AlertModel[];
}

export interface AlertModel {
    id: number,
    message: string,
    active?: boolean,
    alert_type: string,
    ttl?: number,
    sticky?: boolean
}

const initialState: AlertState = <AlertState>{
    alerts: []
}

export const alertReducer = createReducer(
    initialState,
    on(AlertActions.EmitAlertAction, (state,res) => {
        //get latest alert and increment id
        const last_alert = state.alerts[state.alerts.length - 1]
        const new_id = (last_alert ? last_alert.id : 0) + 1;

        //remove type field
        const { type, ...new_alert }: any = res;
        new_alert.id = new_id;
        var new_alerts_list: AlertModel[] = [...state.alerts, new_alert];
        return {
            ...state,
            alerts: new_alerts_list
        };
    }),
    on(AlertActions.CloseAlertAction,(state,res) => {
        let new_alerts_list: AlertModel[] = state.alerts.filter(alert => alert.id !== res.id)
        return {...state, alerts: new_alerts_list};
    })
)