import { createReducer, on } from "@ngrx/store";
import * as GemstonesActions from "./gemstones.actions";


export interface PdfMetadataModel {
    hash: string;
    encoding?: string;
    md5: string;
    mimetype?: string;
    size: number;
}

export interface GemstonesState {
    pdfHash: string;
    tokenId: string;
    gemstones: any[];
}

const initialState: GemstonesState = <GemstonesState>{
    pdfHash: null,
    tokenId: null,
    gemstones: []
};

export const gemstonesReducer = createReducer(
    initialState,
    on(GemstonesActions.SetPdfTokenIdAction, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload
        });
    }),
    on(GemstonesActions.PdfMintSuccess, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload.events.Transfer.returnValues.tokenId
        });
    }),
    on(GemstonesActions.AddStageAction, (state, action) => {
        return Object.assign({}, state, {
            bottles: state.gemstones.map(gem => {
                if (gem.tokenId === action.payload.tokenId) {
                    let stages = gem.stages || [];
                    stages.push(action.payload);

                    return Object.assign({}, gem, { stages: stages });

                } else {
                    return gem;
                }
            })
        })
    })
);
