import { createAction, props } from "@ngrx/store";

import { toPayload } from "../shared";


export const selectMenuAction = createAction(
    "[Layout] Select menu item",
    props<{item: string}>()
);

export const ExpandMenuAction = createAction(
    "[Layout] Expand Menu"
);

export const CollapseMenuAction = createAction(
    "[Layout] Collapse Menu"
);

export const ShowSubmenuAction = createAction(
    "[Layout] Show Submenu"
);

export const HideSubmenuAction = createAction(
    "[Layout] Hide Submenu"
);

export const ToggleLoadingMaskAction = createAction(
    "[Layout] Toggle Loading Mask"
);

export const ShowLoadingMaskAction = createAction(
    "[Layout] Show Loading Mask"
);

export const HideLoadingMaskAction = createAction(
    "[Layout] Hide Loading Mask"
);

export const ToggleFullMenuAction = createAction(
    "[Layout] Toggle Full Menu"
);

export const SetFullMenuAction = createAction(
    "[Layout] Set Full Menu",
    toPayload<boolean>
);

export const ToggleThemeAction = createAction(
    "[Layout] Toggle Theme",
    (theme?: "light" | "dark") => ({payload: theme})
);