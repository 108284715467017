<div class="page-title">
    Customer Loyalty
</div>

<div class="open-btn-con">
    <p-button label="Start Demo" routerLink="rewards_card"></p-button>
</div>

<div class="description-con">
    Connect your existing Web2 customer loyalty program to a Web3 counterpart, establishing a robust system where users can securely earn and redeem digital tokens in exchange for exclusive access to unique rewards.
</div>

<div class="steps-con">
    <div *ngFor="let step of steps; let i=index;" class="step-box">
        <div class="step-icon">
            <i class="mdi {{step.icon}}"></i>
        </div>

        <h3>{{ i + 1 }}. {{ step.name }}</h3>

        <div class="step-description">
            {{ step.description }}
        </div>

        <div class="action-btn">
            <p-button
                label="Start Demo"
                [style]="{'width':'100%'}"
                [routerLink]="step.routerLink"
            ></p-button>
        </div>
    </div>
</div>

<div class="apis-con">
    <div *ngFor="let api of apis; let i=index;" class="api-block">
        <div class="api-title">
            API Request - {{ i + 1 }}
        </div>

        <div class="api-description">
            {{ api.description }}
        </div>

        <p-panel>
            <ng-template pTemplate="header">
                <div class="panel-header">
                    <p-dropdown
                        [options]="codeOptions"
                        [formControl]="codeControls.at(i)"
                    ></p-dropdown>

                    <p-button
                        label="Copy"
                        icon="mdi mdi-content-copy"
                        (click)="copyCode(i)"
                    ></p-button>
                </div>
            </ng-template>

            <div class="panel-body">
                <ng-container *ngFor="let block of api.codeBlocks; let j=index;">
                    <pre [class.hidden]="codeControls.at(i).value !== j"><code>
{{ block.trim() }}
                    </code></pre>
                </ng-container>
            </div>
        </p-panel>
    </div>
</div>