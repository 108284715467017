import { createAction, props } from "@ngrx/store";

export const loginUserAction = createAction(
    "[User] Login user"
);

export const loginUserSuccessAction = createAction(
    "[User] Login successfully",
    props<{username: string, balance: number}>()
);

export const loginUserFailAction = createAction(
    "[User] Login failed"
);

export const checkUserConnectionAction = createAction(
    "[User] Check user wallet connection"
);

export const SetBlockChainAction = createAction(
    "[User] Set Blockchain",
    (chainId: string) => ({ payload: chainId })
);

export const SetBlockChainSuccessAction = createAction(
    "[User] Set Blockchain Success",
    props<any>()
);

export const SetBlockChainFailureAction = createAction(
    "[User] Set Blockchain Failure",
    (err: string) => ({ payload: err })
);