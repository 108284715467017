import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subscription, firstValueFrom } from "rxjs";
import { SelectItem } from "primeng/api";

import { AppStore } from "../../../store";
import { EmitAlertAction } from "../../../shared/components/alerts";
import { CustomerLoyaltyService } from "../customer-loyalty.service";
import { LoadRoyaltyCardAction } from "../customer-loyalty.actions";
import { ShowLoadingMaskAction, HideLoadingMaskAction } from "../../../layout";
import { getBlockchain } from "../../../user/user.selectors";


@Component({
    selector: "loyalty-card-creation-form",
    templateUrl: "./creation-form.component.html",
    styleUrls: ["./creation-form.component.scss"]
})
export class LoyaltyCardCreationForm implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private currentBlockchain: string;

    public form: FormGroup;
    public merchantOptions: SelectItem[] = [];
    public programOptions: SelectItem[] = [];

    public metadataUri: string;
    public tokenId: string;

    constructor(
        private store: Store<AppStore>,
        private service: CustomerLoyaltyService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(getBlockchain).subscribe(chain => {
                this.currentBlockchain = chain;
            })
        );

        this.form = new FormGroup({
            name: new FormControl<string>("", Validators.required),
            dob: new FormControl<string>("", Validators.required),
            phone: new FormControl<string>("", Validators.required),
            merchant: new FormControl<string>("", Validators.required),
            startDate: new FormControl<string>("", Validators.required),
            programName: new FormControl<string>("", Validators.required)
        });

        this.merchantOptions = [
            {
                label: "T-Mobile",
                value: "T-Mobile"
            },
            {
                label: "Target",
                value: "Target"
            },
            {
                label: "Best Buy",
                value: "Best Buy"
            },
            {
                label: "Starbucks",
                value: "Starbucks"
            }
        ];

        this.programOptions = [
            {
                label: "Shopper's Paradise",
                value: "Shopper's Paradise"
            },
            {
                label: "Rewards Emporium",
                value: "Rewards Emporium"
            },
            {
                label: "Retail Rewards Club",
                value: "Retail Rewards Club"
            },
            {
                label: "Elite Shopping Rewards",
                value: "Elite Shopping Rewards"
            },
            {
                label: "Premier Retailer Program",
                value: "Premier Retailer Program"
            },
            {
                label: "Savvy Shopper Rewards",
                value: "Savvy Shopper Rewards"
            },
            {
                label: "Shop & Earn VIP",
                value: "Shop & Earn VIP"
            },
            {
                label: "Preferred Merchant Rewards",
                value: "Preferred Merchant Rewards"
            },
            {
                label: "Retail Excellence Club",
                value: "Retail Excellence Club"
            },
            {
                label: "Exclusive Marketplace Rewards",
                value: "Exclusive Marketplace Rewards"
            }
        ];

        this.fillExampleData();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    fillExampleData() {
        this.form.patchValue({
            name: "John Doe",
            dob: "01/01/1980",
            phone: "(408) 123-1234",
            merchant: "T-Mobile",
            startDate: "01/01/2022",
            programName: "Shopper's Paradise"
        });
    }

    setWeb3Params() {
        if (!this.form.valid) {
            this.store.dispatch(EmitAlertAction({
                message: "Please fill out all fields first.",
                alert_type: "info"
            }));
            return;
        }

        this.store.dispatch(ShowLoadingMaskAction());

        firstValueFrom(
            this.service.uploadMetadata({
                name: this.form.value["programName"],
                description: this.form.value["programName"],
                merchant_name: this.form.value["merchant"],
                customer_name: this.form.value["name"],
                customer_dob: this.form.value["dob"],
                customer_phone: this.form.value["phone"],
                member_since: this.form.value["startDate"]
            })
        ).then(response => {
            this.metadataUri = response["path"];

            this.store.dispatch(HideLoadingMaskAction());

            this.store.dispatch(EmitAlertAction({
                message: "NFT Metadata added to IPFS.",
                alert_type: "success"
            }));

        }).catch(err => {
            console.log("Error setting parameters:", err);

            this.store.dispatch(HideLoadingMaskAction());

            this.store.dispatch(EmitAlertAction({
                message: "Upload NFT MetaData failed, please try again.",
                alert_type: "error"
            }));
        });
    }

    mintRewardsCard() {
        if (!this.metadataUri) {
            this.store.dispatch(EmitAlertAction({
                message: "You must Set Parameters before minting your Rewards Card.",
                alert_type: "info"
            }));
            return;
        }

        this.store.dispatch(ShowLoadingMaskAction());

        firstValueFrom(
            this.service.mintCard(this.metadataUri)
        ).then(data => {
            this.store.dispatch(HideLoadingMaskAction());

            this.store.dispatch(EmitAlertAction({
                message: "NFT Mint successful.",
                alert_type: "success"
            }));

            this.tokenId = data.events.Transfer.returnValues.tokenId;

            //add link based on current selected chainID
            let nftLink_ethereum = "https://sepolia.etherscan.io/nft/0x4a1d383ece83c62854673d9d0a64b1efc4161951/" + (this.tokenId || "");
            let nftLink_polygon = "https://mumbai.polygonscan.com/token/0x62dbbedd28d2cc3a3c05e39dc97da8aca50e06d2?a=" + (this.tokenId || "");
            let nftLink = (this.currentBlockchain === '80001' ? nftLink_polygon : nftLink_ethereum);

            // Store data in rewards card
            this.store.dispatch(
                LoadRoyaltyCardAction(
                    Object.assign({}, this.form.value, {
                        tokenId: this.tokenId,
                        link: nftLink
                    })
                )
            );

        }).catch(err => {
            console.log("Error minting rewards card:", err);

            this.store.dispatch(HideLoadingMaskAction());

            this.store.dispatch(EmitAlertAction({
                message: "There was an error minting your rewards card. Please try again later.",
                alert_type: "error"
            }));
        });
    }
}