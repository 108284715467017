import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from "./auth.guard";
import { WalletComponent } from './wallet/wallet.component';
import { CreateTokenComponent } from './create-token/create-token.component';
import { LoginPageComponent } from './login-page';

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        component: LoginPageComponent
    },
    {
        path: "wallet",
        canActivate: [AuthGuard],
        component: WalletComponent
    }, 
    {
        path: "create_token",
        canActivate: [AuthGuard],
        component: CreateTokenComponent
    },
    {
        path: "demos",
        canActivate: [AuthGuard],
        loadChildren: () =>
            import("./demos/demos.module")
                .then(m => m.DemosModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
