<div class="main-header">
    <div *ngIf="headerConfig?.containsSubmenu" class="sub-header" [class.expanded]="showMenu">
        <div class="sub-header-text" [class.shown]="!headerConfig.submenuIconOnly">
            {{ headerConfig.title }}
        </div>

        <div class="sub-header-icon" [class.shown]="headerConfig.submenuIconOnly">
            <a routerLink="./">
                <img [src]="themeState
                    ? 'assets/images/tx-smw.png'
                    : 'assets/images/tx-smb.png'"
                >
            </a>
        </div>
    </div>

    <div *ngIf="!headerConfig?.hide" class="banner">
        <span *ngIf="headerConfig.parent" class="main-title">
            <i class="mdi {{headerConfig.parent.icon}}"></i>
            {{ headerConfig.parent.title }}&nbsp;/&nbsp;
        </span>

        <span class="sub-title">
            <i *ngIf="headerConfig.titleIcon" class="mdi {{headerConfig.titleIcon}}"></i>

            <img *ngIf="headerConfig.titleImage" [src]="headerConfig.titleImage">

            {{ headerConfig.title }}
        </span>
    </div>

    <div class="header-right">
        <theme-switcher></theme-switcher>

        <login></login>
    </div>

    <div class="mobile-menu-con" (click)="toggleFullMenu()">
        <i class="mdi mdi-menu"></i>
    </div>
</div>
