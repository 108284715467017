import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { firstValueFrom } from "rxjs";

import { AppStore } from "../store";
import { EmitAlertAction } from '../shared/components/alerts/alerts.actions';
import { TokenService } from '../shared/services';
import { ShowLoadingMaskAction, HideLoadingMaskAction } from '../layout';


@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {
    public nfts: any[] = [];
    public exampleSignCode: string = "0x4C532D6E6674";

    constructor(
        private store: Store<AppStore>,
        private service: TokenService
    ) {}

    ngOnInit() {
        // this.fetchTokens();
        // this.displayExampleTokens();
    }

    fetchTokens() {
        this.store.dispatch(ShowLoadingMaskAction());

        this.service.getNFTWallet(this.exampleSignCode)
            .then((wallet: any) => {
                this.nfts = wallet;

                let mux = this.nfts.length;
                let completeItem = () => {
                    if (--mux <= 0) {
                        this.store.dispatch(HideLoadingMaskAction());
                        this.store.dispatch(EmitAlertAction({
                            message: "Wallet fetch success!",
                            alert_type: "success"
                        }));
                    }
                };

                this.nfts.forEach(nft => {
                    firstValueFrom(this.service.getNFTRawData(nft.nft_raw_data))
                        .then(data => {
                            nft.raw_data = Object.assign({}, data);

                            completeItem();

                        }).catch(err => {
                            console.log("Fetch raw data err:", nft.nft_raw_data, err);

                            this.store.dispatch(EmitAlertAction({
                                message: "There was an error fetching the metadata for your item.",
                                alert_type: "error"
                            }));

                            completeItem();
                        });
                });

            }).catch(err => {
                console.log("Fetch wallet err:", err);

                this.store.dispatch(EmitAlertAction({
                    message: "There was an error fetching your digital wallet. Please try again later.",
                    alert_type: "error"
                }));
            });
    }

    displayExampleTokens() {
        const targetLength = 5;
        this.nfts = [];
        let example = {
            "image": "https://ls.infura-ipfs.io/ipfs/QmTFUYf6jNRpzhrMntLuNAcimVZ5d7aGheagbLyT2PcxKU",
            "price": 1,
            "name": "Name 1",
            "description": "desc",
            "nft_type": "auth",
            "nft_raw_data": "85d3ad2dec15712bb77414efbfcb303d2a34251250d240cf1a974d56a973319ab9437b6b7e547745209b2b20257283ce",
            "token_id": "1691185572789",
            "token_address": "0x4a1d383ece83c62854673d9d0a64b1efc4161951",
            raw_data: {
                "ID": "0x0a432bd109d4323d2806",
                "date": "01/20/2023",
                "AuthType": "User",
                "doc_storage": "web2",
                "SubjectID": "dcb3a065-a29c-4b38-ac5c-d8de8790905a",
                "CryptographicSignature": "e6a37d55516e06e09771e27a457e36eb32bac6a7",
                "Updatable": "true",
                "Encrypt": "true",
                "nft_type": "auth",
                "nft_doc_ipfs": "",
                "nft_doc_s3": "Web3/1691185563155_hands2.png"
            }
        };

        for (let i=0; i < targetLength; i++) {
            this.nfts.push(Object.assign({}, example));
        }
    }
}
