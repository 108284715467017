<div class="dropzone" ng2FileDrop [uploader]="uploader" >
    <div *ngIf="!file">
        <div class="con2">
            <span class="message">Drag and Drop Files</span>
        </div>
        <div class="con2">
            <span class="message">or</span>
        </div>
        <div>
            <button class="file-button" (click)="fileInput.click()">
                <span class="message file-message">Choose File</span>
                <input #fileInput type="file" ng2FileSelect [uploader]="uploader" style="display:none;" />
            </button>
        </div> 
    </div>

    <div *ngIf="file">
        <span class="message">{{fileName}}</span>
        <i class="pi pi-minus-circle remove-file" (click)="removeFile()"></i>
    </div>
    
</div>