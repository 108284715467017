<div class="page-title">
    Earn Rewards
</div>

<div class="earn-items-con">
    <div *ngFor="let item of earnItems"
        class="item-card"
        (click)="earnPoints(item)"
    >
        <div class="card-img-con">
            <img [src]="item.image"><br/>
        </div>

        <div class="card-text-con">
            <div class="card-name">
                {{ item.name }}
            </div>
    
            <div class="card-price">
                {{ item.price }}
            </div>
    
            <div class="card-points">
                Earn Rewards Points {{ item.points }}
            </div>
        </div>
    </div>
</div>