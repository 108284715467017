import { createAction } from "@ngrx/store";

export const PdfTokenizerActionTypes = {
    SET_TOKENID: "[PDF Tokenizer] Set TokenId",
    PDF_MINT: "[PDF Tokenizer] Mint PDF",
    PDF_MINT_SUCCESS: "[PDF Tokenizer] Mint PDF Success",
    PDF_MINT_FAILURE: "[PDF Tokenizer] Mint PDF Failure"
}

export const SetPdfTokenIdAction = createAction(
    PdfTokenizerActionTypes.SET_TOKENID,
    (id: string) => ({ payload: id })
);

export const PdfMint = createAction(
    PdfTokenizerActionTypes.PDF_MINT,
    (data: any) => ({ payload: data })
);

export const PdfMintSuccess = createAction(
    PdfTokenizerActionTypes.PDF_MINT_SUCCESS,
    (res: any) => ({ payload: res })
);

export const PdfMintFail = createAction(
    PdfTokenizerActionTypes.PDF_MINT_FAILURE,
    (err: any) => ({ payload: err })
);

export const viewPdf = createAction(
    "[PDF Tokenizer] view PDF",
    (tokenId: string) => ({ tokenId: tokenId })
);

export const viewPdfSuccess = createAction(
    "[PDF Tokenizer] view PDF success",
    (data: any) => ({payload:data})
);

export const viewPdfFail = createAction(
    "[PDF Tokenizer] view PDF Fail",
    (err: any) => ({payload:err})
);
