import { createReducer, on } from "@ngrx/store";

import { RewardsCardModel, RedeemItemModel } from "../../shared/models";
import { DEFAULT_STARTING_POINTS } from "../../shared/utilities/constants";
import * as CustomerLoyaltyActions from "./customer-loyalty.actions";


export interface CustomerLoyaltyState {
    rewardsCard: RewardsCardModel;
    points: number;
    redemptionHistory: RedeemItemModel[];
}

const initialState: CustomerLoyaltyState = <CustomerLoyaltyState>{
    rewardsCard: null,
    points: DEFAULT_STARTING_POINTS,
    redemptionHistory: []
};

export const customerLoyaltyReducer = createReducer(
    initialState,
    on(CustomerLoyaltyActions.LoadRoyaltyCardAction, (state, action) => {
        return Object.assign({}, state, {
            rewardsCard: action.payload,
            points: DEFAULT_STARTING_POINTS
        });
    }),
    on(CustomerLoyaltyActions.AdjustPointsAction, (state, action) => {
        return Object.assign({}, state, {
            points: state.points + action.payload
        });
    }),
    on(CustomerLoyaltyActions.RedeemItemSuccessAction, (state, action) => {
        return Object.assign({}, state, {
            points: state.points - action.payload.points,
            redemptionHistory: [
                ...state.redemptionHistory,
                action.payload
            ]
        });
    })
);