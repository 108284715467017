import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { AppStore } from "../store";
import { DEMO_LIST, BLOCKCHAIN_OPTIONS } from '../shared/utilities/constants';
import { SetBlockChainAction } from "../user/user.actions";
import { getBlockchain } from "../user/user.selectors";


@Component({
    selector: 'app-left-nav',
    templateUrl: './left-nav.component.html',
    styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent implements OnInit {
    @Input() menuExpanded: boolean;

    private subscriptions: Subscription[] = [];

    public selectedChain: string;
    public blockchainOptions: any[];

    public expanded: boolean;
    public menuItems: any[];
    public panelItems: any[];

    constructor(
        private store: Store<AppStore>,
        private router: Router
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(event => {
                    this.findSelected((event as NavigationEnd).url);
                })
        );

        this.blockchainOptions = BLOCKCHAIN_OPTIONS.filter(chain => chain.selectable);

        this.subscriptions.push(
            this.store.select(getBlockchain).subscribe(chain => {
                this.selectedChain = chain;
            })
        );

        this.menuItems = [
            {
                label: '<i class="mdi mdi-file-star-four-points-outline"></i> Dev Minter',
                escape: false,
                collapsedLabel: "Dev Minter",
                collapsedIcon: "mdi-file-star-four-points-outline",
                styleClass: "minter-panel",
                items: [
                    {
                        label: 'Wallet',
                        routerLink: '/wallet',
                        collapsedLabel: "Wallet",
                        collapsedIcon: "mdi mdi-file-cabinet"
                    },
                    {
                        label: `<div class="fake-button">Create Token</div>`,
                        escape: false,
                        routerLink: "/create_token",
                        collapsedLabel: "Create Token",
                        collapsedIcon: "mdi-plus-box"
                    }
                ]
            }
        ]

        this.panelItems = [
            {
                label: 'Demos',
                expanded: true,
                routerLink: "/demos",
                icon: 'mdi mdi-laptop',
                items: DEMO_LIST.map(demo => ({
                    label: demo.name,
                    routerLink: demo.routerLink
                }))
            },
            {
                label: 'Developer Tools',
                expanded: true,
                icon: 'mdi mdi-wrench-outline',
                items: []
            }
        ];
    }

    findSelected(url: string) {
        if (url.startsWith("/demos")) {
            this.panelItems[0].items = this.panelItems[0].items.map((item: any) => {
                return Object.assign(item, {
                    selected: url.startsWith(item.routerLink)
                });
            });
        }
    }

    changeBlockchain(event: any) {
        this.store.dispatch(SetBlockChainAction(event.value));
    }
}
