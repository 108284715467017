import { createAction } from "@ngrx/store";

export const SafetyCertActionTypes = {
    SET_TOKENID: "[Safety Certification] Set TokenId",
    PDF_MINT: "[Safety Certification] Mint PDF",
    PDF_MINT_SUCCESS: "[Safety Certification] Mint PDF Success",
    PDF_MINT_FAILURE: "[Safety Certification] Mint PDF Failure",
    PDF_VIEW: "[Safety Certification] View PDF",
    PDF_VIEW_SUCCESS: "[Safety Certification] View PDF Success",
    PDF_VIEW_FAILURE: "[Safety Certification] View PDF Failure"
}

export const SetPdfTokenIdAction = createAction(
    SafetyCertActionTypes.SET_TOKENID,
    (id: string) => ({ payload: id })
);

export const PdfMint = createAction(
    SafetyCertActionTypes.PDF_MINT,
    (data: any) => ({ payload: data })
);

export const PdfMintSuccess = createAction(
    SafetyCertActionTypes.PDF_MINT_SUCCESS,
    (res: any) => ({ payload: res })
);

export const PdfMintFail = createAction(
    SafetyCertActionTypes.PDF_MINT_FAILURE,
    (err: any) => ({ payload: err })
);

export const viewPdf = createAction(
    SafetyCertActionTypes.PDF_VIEW,
    (tokenId: string) => ({ tokenId: tokenId })
);

export const viewPdfSuccess = createAction(
    SafetyCertActionTypes.PDF_VIEW_SUCCESS,
    (data: any) => ({payload:data})
);

export const viewPdfFail = createAction(
    SafetyCertActionTypes.PDF_VIEW_FAILURE,
    (err: any) => ({payload:err})
);