import { createReducer, on } from "@ngrx/store";

import * as CoffeeActions from "./coffee.actions";


export interface CoffeeState {
    tokenId: string[];
    HARVESTING: any[];
    PROCESSING: any[];
    TRACKING: any[];
}

const initialState: CoffeeState = <CoffeeState>{
    tokenId: [],
    HARVESTING: [],
    PROCESSING: [],
    TRACKING: []
};

export const coffeeReducer = createReducer(
    initialState,
    on(CoffeeActions.SaveTokenIdAction, (state, action) => {
        return Object.assign({}, state, {
            tokenId: [
                ...state.tokenId,
                action.payload
            ]
        });
    }),
    on(CoffeeActions.TokenMintSuccess, (state, action) => {
        return Object.assign({}, state, {
            tokenId: [
                ...state.tokenId,
                action.payload.tokenId
            ],
            [action.stage]: [
                ...state[action.stage],
                action.payload
            ]
        });
    }),
    on(CoffeeActions.LoadBatchAction, (state, action) => {
        return Object.assign({}, state, {
            [action.stage]: [
                ...state[action.stage],
                action.payload
            ]
        });
    }),
    on(CoffeeActions.AddStageAction, (state, action) => {
        return Object.assign({}, state, {
            [action.stage]: state[action.stage].map(token => {
                if (token.tokenId === action.payload.tokenId) {
                    let stages = token.stages?.slice() || [];
                    stages.push(action.payload);

                    return Object.assign({}, token, { stages: stages });

                } else {
                    return token;
                }
            })
        })
    })
);
