<div class="theme-switcher-con">
    <div class="switch-box"
        (click)="toggleTheme()"
    >
        <div class="switch-con" [ngClass]="{ 'dark': themeState, 'light': !themeState }">
            <i class="mdi"
                [ngClass]="{
                    'mdi-moon-waning-crescent': themeState,
                    'mdi-white-balance-sunny': !themeState
                }"
            ></i>
        </div>
    </div>
</div>