<p-tabView>
    <p-tabPanel header="Redeemed Items">
        <div *ngFor="let item of redeemed" class="reward-item">
            <div class="reward-1">
                <img class="reward-img" [src]="item.image">
            </div>
            <div class="reward-info">
                <span class="line name">{{item.name}}</span>
                <span class="line points">Rewards Points: {{item.points}}</span>
                <span class="line receipt">
                    <a target="_blank" href="{{blockchainUrl}}/tx/{{item.hash}}">
                        Blockchain Receipt
                    </a>
                </span>
            </div>
            <div class="qr">
                <img class="qr-code" src="../../../../assets/images/qrcode_1.png">
            </div>
        </div>
    </p-tabPanel>

    <p-tabPanel header="Rewards Tracker">
        <div class="refresh-btn-con">
            <p-button
                label="Refresh Rewards Info"
                (onClick)="trackRewards()"
            ></p-button>
        </div>

        <p-accordion>
            <p-accordionTab *ngFor="let item of rewardsHistory"
                header="{{ (item.rewards_action?.toUpperCase() === 'BURN') ? 'REDEEMED' : 'EARNED' }}: {{ item.name }}"
            >
                <ng-template pTemplate="content">
                    <div class="rewards-tracker-body">
                        <div>
                            Name: {{ item.name }}
                        </div>

                        <div>
                            Price: {{ item.price }}
                        </div>

                        <div>
                            Points: {{ item.points }}
                        </div>

                        <div>
                            Timestamp: {{ item.timestamp | date: "short" }}
                        </div>

                        <div class="image-con">
                            <img [src]="item.image"/>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </p-tabPanel>
</p-tabView>