import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of, from } from 'rxjs';
import { map, exhaustMap, catchError, mergeMap, withLatestFrom, switchMap } from 'rxjs/operators';

import * as CustomerLoyaltyActions from "./customer-loyalty.actions";
import { CustomerLoyaltyService } from "./customer-loyalty.service";
import { getRewardsCard } from "./customer-loyalty.selectors";

import { AppStore } from "../../store";
import { ShowLoadingMaskAction, HideLoadingMaskAction } from "../../layout";
import { EmitAlertAction } from "src/app/shared";


@Injectable()
export class CustomerLoyaltyEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppStore>,
        private service: CustomerLoyaltyService
    ) {}

    /*
    earnReward$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerLoyaltyActions.EarnRewardAction),
        exhaustMap((action) =>
            this.service.earnReward()
        )
    ));
    */

    redeemItem$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerLoyaltyActions.RedeemItemAction),
        withLatestFrom(
            this.store.select(getRewardsCard)
        ),
        exhaustMap(([action, card]) => {
            // Could put error checking here
            // Points comparison, action.payload.points > store.select.points
            // Check if card is !null and card.tokenId exists

            this.store.dispatch(ShowLoadingMaskAction());

            return this.service.redeemReward(
                card.tokenId,
                action.payload
            ).pipe(
                map(response => CustomerLoyaltyActions.RedeemItemSuccessAction(
                    Object.assign(
                        {},
                        action.payload,
                        { hash: response.transactionHash }
                    )
                )),
                catchError(err => of(CustomerLoyaltyActions.RedeemItemFailureAction(err)))
            )
        })
    ));

    redeemItemSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerLoyaltyActions.RedeemItemSuccessAction),
        mergeMap((action) => {
            let actions = [
                HideLoadingMaskAction(),
                EmitAlertAction({
                    message: "Reward successfully redeemed.",
                    alert_type: "success"
                })
            ];

            return actions;
        })
    ));

    redeemItemFailure$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerLoyaltyActions.RedeemItemFailureAction),
        mergeMap((action) => {
            let actions = [
                HideLoadingMaskAction(),
                EmitAlertAction({
                    message: "There was an error redeeming your reward. Please try again later.",
                    alert_type: "error"
                })
            ];

            return actions;
        })
    ));
}