import { createSelector } from "@ngrx/store";
import { AppStore } from "../store";

export const selectUserState = (state:AppStore) => state.user;

export const getLoginStatus = createSelector(
    selectUserState,
    state => state.loginStatus
);

export const getUserName = createSelector(
    selectUserState,
    (state) => state.username
);

export const getUserBalance = createSelector(
    selectUserState,
    (state) => state.balance
);

export const getBlockchain = createSelector(
    selectUserState,
    state => state.blockchain
);