import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseService } from "./base.service";


@Injectable()
export class LayoutService extends BaseService {
    constructor(
        http: HttpClient
    ) {
        super(http);
    }

    switchTheme(mode: string) {
        let themeLink: HTMLLinkElement = <HTMLLinkElement>(
            document.getElementById("theme-css")
        );

        switch(mode) {
            case "dark": {
                themeLink.href = themeLink.href.replace("default", "dark-mode");
                break;
            }

            case "light":
            default: {
                themeLink.href = themeLink.href.replace("dark-mode", "default");
                break;
            }
        }
    }
}