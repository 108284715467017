import { ActionReducerMap } from "@ngrx/store";

import { LayoutState, layoutReducer } from "./layout/layout.reducer";
import { UserState, userReducer } from "./user/user.reducer";
import { AlertState, alertReducer } from "./shared/components/alerts/alerts.reducer";

import { CustomerLoyaltyState, customerLoyaltyReducer } from "./demos/customer-loyalty";
import { PdfTokenizerState, pdfTokenizerReducer } from "./demos/pdf-tokenizer/pdf-tokenizer.reducer";
import { DocAuthState, docAuthReducer } from "./demos/doc-auth/doc-auth.reducer";
import { RareWhiskeyState, rareWhiskeyReducer } from "./demos/rare-whiskey/rare-whiskey.reducer";
import { SafetyCertState, safetyCertReducer } from "./demos/safety-certification/safety-cert.reducer";
import { GemstonesState, gemstonesReducer } from "./demos/gemstones/gemstones.reducer";
import { DataVaultState, dataVaultReducer } from "./demos/data-vault/data-vault.reducer";
import { CoffeeState, coffeeReducer } from "./demos/coffee/coffee.reducer";
import { BloodSampleState, bloodSampleReducer } from "./demos/blood-sample/blood-sample.reducer";
import { TicketState, ticketReducer } from "./demos/tickets/tickets.reducer";
import { DiplomaState, diplomaReducer } from "./demos/diploma/diploma.reducer";
import { ArtState, artReducer } from "./demos/art/art.reducer";
import { DrugState, drugReducer } from "./demos/drug/drug.reducer";

export interface AppStore {
    layout: LayoutState
    user: UserState
    alerts: AlertState,
    customerLoyalty: CustomerLoyaltyState,
    pdfTokenizer: PdfTokenizerState,
    docAuth: DocAuthState,
    rareWhiskey: RareWhiskeyState,
    safetyCert: SafetyCertState,
    gemstones: GemstonesState,
    dataVault: DataVaultState,
    coffee: CoffeeState,
    bloodSample: BloodSampleState,
    tickets: TicketState,
    diploma: DiplomaState,
    art: ArtState,
    drug: DrugState
};

export const reducers: ActionReducerMap<AppStore> = {
    layout: layoutReducer,
    user: userReducer,
    alerts: alertReducer,
    customerLoyalty: customerLoyaltyReducer,
    pdfTokenizer: pdfTokenizerReducer,
    docAuth: docAuthReducer,
    rareWhiskey: rareWhiskeyReducer,
    safetyCert: safetyCertReducer,
    gemstones: gemstonesReducer,
    dataVault: dataVaultReducer,
    coffee: coffeeReducer,
    bloodSample: bloodSampleReducer,
    tickets: ticketReducer,
    diploma: diplomaReducer,
    art: artReducer,
    drug: drugReducer
};