import { createReducer, on } from "@ngrx/store";
import * as SafetyCertActions from "./safety-cert.actions";


export interface PdfMetadataModel {
    hash: string;
    encoding?: string;
    md5: string;
    mimetype?: string;
    size: number;
}

export interface SafetyCertState {
    pdfHash: string;
    tokenId: string;
}

const initialState: SafetyCertState = <SafetyCertState>{
    pdfHash: null,
    tokenId: null
};

export const safetyCertReducer = createReducer(
    initialState,
    on(SafetyCertActions.SetPdfTokenIdAction, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload
        });
    }),
    on(SafetyCertActions.PdfMintSuccess, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload.events.Transfer.returnValues.tokenId
        });
    })
);
