import { createAction } from "@ngrx/store";

import { toPayload } from "../../shared/utilities/constants";
import { WhiskeyBarrelModel, WhiskeyBottleModel } from "./rare-whiskey.models";

export const RareWhiskeyActionTypes = {
    LOAD_BOTTLE_GALLERY: "[Rare Whiskey] Load Bottle Gallery",
    LOAD_BOTTLE_GALLERY_SUCCESS: "[Rare Whiskey] Load Bottle Gallery Success",
    LOAD_BOTTLE_GALLERY_FAILURE: "[Rare Whiskey] Load Bottle Gallery Failure",
    LOAD_BARREL_DATA: "[Rare Whiskey] Load Barrel Data",
    ADD_BARREL_STAGE: "[Rare Whiskey] Add Barrel Stage",
    LOAD_BOTTLE_DATA: "[Rare Whiskey] Load Bottle Data",
    ADD_BOTTLE_STAGE: "[Rare Whiskey] Add Bottle Stage"
};

export const LoadBottleGalleryAction = createAction(
    RareWhiskeyActionTypes.LOAD_BOTTLE_GALLERY
);

export const LoadBottleGallerySuccessAction = createAction(
    RareWhiskeyActionTypes.LOAD_BOTTLE_GALLERY_SUCCESS,
    toPayload<WhiskeyBottleModel[]>
);

export const LoadBottleGalleryFailureAction = createAction(
    RareWhiskeyActionTypes.LOAD_BOTTLE_GALLERY_FAILURE,
    toPayload<string>
);

export const LoadBarrelDataAction = createAction(
    RareWhiskeyActionTypes.LOAD_BARREL_DATA,
    toPayload<WhiskeyBarrelModel>
);

export const AddBarrelStageAction = createAction(
    RareWhiskeyActionTypes.ADD_BARREL_STAGE,
    toPayload<any>
);

export const LoadBottleDataAction = createAction(
    RareWhiskeyActionTypes.LOAD_BOTTLE_DATA,
    toPayload<WhiskeyBottleModel>
);

export const AddBottleStageAction = createAction(
    RareWhiskeyActionTypes.ADD_BOTTLE_STAGE,
    toPayload<any>
);