import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { AppStore } from "../../../store";
import { getTheme } from "../../../layout/layout.selectors";
import { ToggleThemeAction } from "../../../layout/layout.actions";


@Component({
    selector: "theme-switcher",
    templateUrl: "./theme-switcher.component.html",
    styleUrls: ["./theme-switcher.component.scss"]
})
export class ThemeSwitcherComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    public themeState: boolean = false;

    constructor(
        private store: Store<AppStore>
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(getTheme).subscribe(theme => {
                this.themeState = (theme === "dark");
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    toggleTheme() {
        this.store.dispatch(ToggleThemeAction());
    }
}