import { createAction } from "@ngrx/store";

export const CoffeeActionTypes = {
    SAVE_TOKENID: "[Coffee] Save TokenId",
    TOKEN_MINT: "[Coffee] Mint Token",
    TOKEN_MINT_SUCCESS: "[Coffee] Mint Token Success",
    TOKEN_MINT_FAILURE: "[Coffee] Mint Token Failure",
    TOKEN_VIEW: "[Coffee] View Token",
    TOKEN_VIEW_SUCCESS: "[Coffee] View Token Success",
    TOKEN_VIEW_FAILURE: "[Coffee] View Token Failure",
    LOAD_BATCH: "[Coffee] Load Batch",
    ADD_STAGE: "[Coffee] Add Stage"
}

export const SaveTokenIdAction = createAction(
    CoffeeActionTypes.SAVE_TOKENID,
    (id: string) => ({ payload: id })
);

export const TokenMint = createAction(
    CoffeeActionTypes.TOKEN_MINT,
    (data: any, key: 'HARVESTING' | 'PROCESSING' | 'TRACKING') => ({ payload: data, stage: key })
);

export const TokenMintSuccess = createAction(
    CoffeeActionTypes.TOKEN_MINT_SUCCESS,
    (data: any, key: 'HARVESTING' | 'PROCESSING' | 'TRACKING') => ({ payload: data, stage: key })
);

export const TokenMintFail = createAction(
    CoffeeActionTypes.TOKEN_MINT_FAILURE,
    (err: any) => ({ payload: err })
);

export const viewToken = createAction(
    CoffeeActionTypes.TOKEN_VIEW,
    (tokenId: string) => ({ tokenId: tokenId })
);

export const viewTokenSuccess = createAction(
    CoffeeActionTypes.TOKEN_VIEW_SUCCESS,
    (data: any) => ({ payload: data })
);

export const viewTokenFail = createAction(
    CoffeeActionTypes.TOKEN_VIEW_FAILURE,
    (err: any) => ({ payload: err })
);

export const LoadBatchAction = createAction(
    CoffeeActionTypes.LOAD_BATCH,
    (data: any, key: 'HARVESTING' | 'PROCESSING' | 'TRACKING') => ({ payload: data, stage: key })
);

export const AddStageAction = createAction(
    CoffeeActionTypes.ADD_STAGE,
    (data: any, key: 'HARVESTING' | 'PROCESSING' | 'TRACKING') => ({ payload: data, stage: key })
);