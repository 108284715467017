import { createReducer, on } from "@ngrx/store";

import * as LayoutActions from './layout.actions';


export interface LayoutState {
    theme: string;
    showLoading: boolean;
    menuExpanded: boolean;
    submenu: boolean;
    activeMenu: string;
    showFullMenu: boolean;
};

const initialState: LayoutState = <LayoutState> {
    theme: "dark",
    showLoading: false,
    menuExpanded: true,
    submenu: false,
    activeMenu: 'Wallet',
    showFullMenu: false
};

export const layoutReducer = createReducer(
    initialState,
    on(LayoutActions.selectMenuAction, (state, {item}) => {
        return Object.assign({}, state, {
            activeMenu: item
        });
    }),
    on(LayoutActions.ExpandMenuAction, (state, action) => {
        return Object.assign({}, state, {
            menuExpanded: true
        });
    }),
    on(LayoutActions.CollapseMenuAction, (state, action) => {
        return Object.assign({}, state, {
            menuExpanded: false
        });
    }),
    on(LayoutActions.ShowSubmenuAction, (state, action) => {
        return Object.assign({}, state, {
            submenu: true
        });
    }),
    on(LayoutActions.HideSubmenuAction, (state, action) => {
        return Object.assign({}, state, {
            submenu: false
        });
    }),
    on(LayoutActions.ToggleLoadingMaskAction, (state) => {
        return Object.assign({}, state, {
            showLoading: !state.showLoading
        });
    }),
    on(LayoutActions.ShowLoadingMaskAction, (state) => {
        return Object.assign({}, state, {
            showLoading: true
        });
    }),
    on(LayoutActions.HideLoadingMaskAction, (state) => {
        return Object.assign({}, state, {
            showLoading: false
        });
    }),
    on(LayoutActions.ToggleFullMenuAction, (state) => {
        return Object.assign({}, state, {
            showFullMenu: !state.showFullMenu
        });
    }),
    on(LayoutActions.SetFullMenuAction, (state, action) => {
        return Object.assign({}, state, {
            showFullMenu: action.payload
        });
    }),
    on(LayoutActions.ToggleThemeAction, (state, action) => {
        return Object.assign({}, state, {
            theme: action.payload
                || (state.theme === "dark" ? "light" : "dark")
        });
    })
);