import { of as observableOf, Observable } from "rxjs";
import { switchMap, mergeMap, take, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { Store, select } from "@ngrx/store";

import { AppStore } from "./store";
import { UserService, getLoginStatus } from "./user";


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private store: Store<AppStore>,
        private userService: UserService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        let unauthorized = () => {
            this.router.navigateByUrl("");
            return observableOf(false);
        };

        return this.store.pipe(
            select(getLoginStatus),
            take(1),
            switchMap((status: boolean) => {
                /**
                 * Three scenarios to consider:
                 * 1) The user just logged into our app - they should have a status of `loggedIn` and our UserEffects
                 * should have handled the request to the server to get the client data (whenever there is a
                 * `LOG_IN_SUCCESS`, our Effects automatically do some things). A side-effect of updating the user
                 * state with their server-side information is that we set their ID in session storage (handled in
                 * UserEffects).
                 */
                if (status) {
                    return observableOf(true);
                }

                /**
                 * 2) The user has not logged in or refreshed the page and is trying to access a route behind this
                 * guard - force them back to the login screen. They will not have a user ID in their session storage
                 * if they haven't logged in.
                 */

                // TODO UserService.userId is currently connected to MetaMask, change to cognito
                // if (!UserService.userId) {
                //     //this.store.dispatch(new StorePath(state.url));
                //     sessionStorage.setItem("redirectUrl", state.url);
                //     return unauthorized();
                // }

                /**
                 * 3) The user has refreshed the page - this means they were already logged in and they have their
                 * userId in session storage. In this case, the `loggedIn` state is false because they didn't just
                 * log in, so we need to go fetch their client data from the server manually.
                 */
                // return this.userService.getClient().pipe(
                //     mergeMap((user: any) => {
                //         this.store.dispatch(new UserUpdateAction(user));
                //         this.store.dispatch(new PageRefreshAction());
                //         // this.chatService.updateUser(user);

                //         if (
                //             user._subscription
                //         ) {
                //             this.router.navigateByUrl("/pro");
                //         }

                //         return observableOf(true);
                //     }),
                //     catchError(() => {
                //         return unauthorized();
                //     })
                // );

                return observableOf(true);
            })
        );
    }
}
