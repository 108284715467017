import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from "../../shared/shared.module";
import { routes } from "./customer-loyalty.routing";
import { CustomerLoyaltyComponent } from "./customer-loyalty.component";
import { CustomerLoyaltyService } from "./customer-loyalty.service";
import { CustomerLoyaltyEffects } from "./customer-loyalty.effects";
import { LoyaltyCardCreationForm } from "./creation-form";
import { EarnRewardsComponent } from "./earn-rewards";
import { RedeemRewardsComponent } from "./redeem-rewards";
import { RewardsCardDisplay } from "./rewards-card";
import { RewardsTracker } from "./rewards-tracker";
import { LandingComponent } from "./landing";

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        EffectsModule.forFeature(CustomerLoyaltyEffects)
    ],
    declarations: [
        CustomerLoyaltyComponent,
        LoyaltyCardCreationForm,
        EarnRewardsComponent,
        RedeemRewardsComponent,
        RewardsCardDisplay,
        RewardsTracker,
        RedeemRewardsComponent,
        LandingComponent
    ],
    exports: [
        CustomerLoyaltyComponent
    ],
    providers: [
        CustomerLoyaltyService
    ]
})
export class CustomerLoyaltyModule {}
