import { createAction } from "@ngrx/store";

import FIELDS_CONFIG from "./fields_config.json";

export const DrugActionTypes = {
    SAVE_TOKENID: "[Drug] Save TokenId",
    TOKEN_MINT: "[Drug] Mint PDF",
    TOKEN_MINT_SUCCESS: "[Drug] Mint PDF Success",
    TOKEN_MINT_FAILURE: "[Drug] Mint PDF Failure",
    TOKEN_VIEW: "[Drug] View PDF",
    TOKEN_VIEW_SUCCESS: "[Drug] View PDF Success",
    TOKEN_VIEW_FAILURE: "[Drug] View PDF Failure",
    LOAD_BATCH: "[Drug] Load Batch",
    ADD_STAGE: "[Drug] Add Stage"
};

export const SaveTokenIdAction = createAction(
    DrugActionTypes.SAVE_TOKENID,
    (id: string) => ({ payload: id })
);

export const TokenMint = createAction(
    DrugActionTypes.TOKEN_MINT,
    (data: any, key: keyof typeof FIELDS_CONFIG) => ({ payload: data, stage: key })
);

export const TokenMintSuccess = createAction(
    DrugActionTypes.TOKEN_MINT_SUCCESS,
    (data: any, key: keyof typeof FIELDS_CONFIG) => ({ payload: data, stage: key })
);

export const TokenMintFail = createAction(
    DrugActionTypes.TOKEN_MINT_FAILURE,
    (err: any) => ({ payload: err })
);

export const viewToken = createAction(
    DrugActionTypes.TOKEN_VIEW,
    (tokenId: string) => ({ tokenId: tokenId })
);

export const viewTokenSuccess = createAction(
    DrugActionTypes.TOKEN_VIEW_SUCCESS,
    (data: any) => ({ payload:data })
);

export const viewTokenFail = createAction(
    DrugActionTypes.TOKEN_VIEW_FAILURE,
    (err: any) => ({ payload:err })
);

export const LoadBatchAction = createAction(
    DrugActionTypes.LOAD_BATCH,
    (data: any, key: keyof typeof FIELDS_CONFIG) => ({ payload: data, stage: key })
);

export const AddStageAction = createAction(
    DrugActionTypes.ADD_STAGE,
    (data: any, key: keyof typeof FIELDS_CONFIG) => ({ payload: data, stage: key })
);