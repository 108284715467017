import { createReducer, on } from "@ngrx/store";

import * as UserActions from './user.actions';
import { DEFAULT_BLOCKCHAIN } from "../shared";

export interface UserState {
    loginStatus: boolean;
    username: string;
    balance: number;
    blockchain: string;
}

const initialState: UserState = <UserState> {
    loginStatus: false,
    username: "",
    balance: 0,
    blockchain: DEFAULT_BLOCKCHAIN
}

export const userReducer = createReducer(
    initialState,
    on(
        UserActions.loginUserSuccessAction,
        (state, res) => ({
            ...state,
            loginStatus: true,
            username: res.username,
            balance: res.balance
        })
    ),
    on(
        UserActions.SetBlockChainAction,
        (state, action) => {
            return Object.assign({}, state, {
                blockchain: action.payload
            });
        }
    )
)