import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileUploader } from 'ng2-file-upload';
import { AppStore } from 'src/app/store';
import { EmitAlertAction } from '../alerts';

@Component({
  selector: 'app-file-dropzone',
  templateUrl: './file-dropzone.component.html',
  styleUrls: ['./file-dropzone.component.scss']
})

export class FileDropzoneComponent implements OnInit, OnDestroy {

  public uploader: FileUploader; 
  public file: File; // Array to store file blobs
  public fileName: string;
  @Output() fileAdded = new EventEmitter<File>();
  @Input() allowedMime: string;

  constructor (private store: Store<AppStore>) {
  }

  ngOnInit(): void {
    if (this.allowedMime) {
      this.uploader  = new FileUploader({ url: '', allowedMimeType: [this.allowedMime] })
    } else {
      this.uploader  = new FileUploader({ url: ''})
    }

    this.uploader.onAfterAddingAll = (uploaded) => {
      this.file = uploaded[0].file.rawFile;
      this.fileName = this.file.name;
      this.fileAdded.emit(this.file);
    }

    //handle upload fails due to not being pdf
    this.uploader.onWhenAddingFileFailed = (failed) => {
      this.store.dispatch(EmitAlertAction({
        message: "Non-pdf file detected. Please upload a PDF file",
        alert_type: "error"
    }));
    }
  }

  ngOnDestroy(): void {

  }

  removeFile(): void {
    this.file = null;
    this.uploader.clearQueue();
    this.fileName = null;
    this.fileAdded.emit(null);
  }

}
