import { Routes } from "@angular/router";

import { CustomerLoyaltyComponent } from "./customer-loyalty.component";
import { LoyaltyCardCreationForm } from "./creation-form";
import { EarnRewardsComponent } from "./earn-rewards";
import { RedeemRewardsComponent } from "./redeem-rewards";
import { LandingComponent } from "./landing";


export const routes: Routes = [
    {
        path: "",
        component: CustomerLoyaltyComponent,
        children: [
            {
                path: "",
                pathMatch: "full",
                component: LandingComponent
            },
            {
                path: "rewards_card",
                component: LoyaltyCardCreationForm
            },
            {
                path: "earn_rewards",
                component: EarnRewardsComponent
            },
            {
                path: "redeem_rewards",
                component: RedeemRewardsComponent
            }
        ]
    }
];
