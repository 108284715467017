import { createReducer, on } from "@ngrx/store";

import * as RareWhiskeyActions from "./rare-whiskey.actions";
import { WhiskeyBarrelModel, WhiskeyBottleModel } from "./rare-whiskey.models";


export interface RareWhiskeyState {
    barrels: WhiskeyBarrelModel[];
    bottles: WhiskeyBottleModel[];
}

const initialState: RareWhiskeyState = <RareWhiskeyState>{
    barrels: [],
    bottles: []
};

export const rareWhiskeyReducer = createReducer(
    initialState,
    on(RareWhiskeyActions.LoadBarrelDataAction, (state, action) => {
        return Object.assign({}, state, {
            barrels: [
                ...state.barrels,
                action.payload
            ]
        });
    }),
    on(RareWhiskeyActions.LoadBottleDataAction, (state, action) => {
        return Object.assign({}, state, {
            bottles: [
                ...state.bottles,
                action.payload
            ]
        });
    }),
    on(RareWhiskeyActions.AddBarrelStageAction, (state, action) => {
        return Object.assign({}, state, {
            barrels: state.barrels.map(barrel => {
                if (barrel.tokenId === action.payload.tokenId) {
                    let stages = barrel.stages || [];
                    stages.push(action.payload);

                    return Object.assign({}, barrel, { stages: stages });

                } else {
                    return barrel;
                }
            })
        });
    }),
    on(RareWhiskeyActions.LoadBottleGallerySuccessAction, (state, action) => {
        return Object.assign({}, state, {
            bottles: action.payload
        });
    }),
    on(RareWhiskeyActions.AddBottleStageAction, (state, action) => {
        return Object.assign({}, state, {
            bottles: state.bottles.map(bottle => {
                if (bottle.tokenId === action.payload.tokenId) {
                    let stages = bottle.stages || [];
                    stages.push(action.payload);

                    return Object.assign({}, bottle, { stages: stages });

                } else {
                    return bottle;
                }
            })
        })
    })
);