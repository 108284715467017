import { createReducer, on } from "@ngrx/store";

import * as DrugActions from "./drug.actions";


export interface DrugState {
    tokenId: string[];
    REGULATORY: any[];
    MANUFACTURING: any[];
    PACKAGING: any[];
    CONSUMPTION: any[];
}

const initialState: DrugState = <DrugState>{
    tokenId: [],
    REGULATORY: [],
    MANUFACTURING: [],
    PACKAGING: [],
    CONSUMPTION: []
};

export const drugReducer = createReducer(
    initialState,
    on(DrugActions.SaveTokenIdAction, (state, action) => {
        return Object.assign({}, state, {
            tokenId: [
                ...state.tokenId,
                action.payload
            ]
        });
    }),
    on(DrugActions.TokenMintSuccess, (state, action) => {
        return Object.assign({}, state, {
            tokenId: [
                ...state.tokenId,
                action.payload.tokenId
            ],
            [action.stage]: [
                ...state[action.stage],
                action.payload
            ]
        });
    }),
    on(DrugActions.LoadBatchAction, (state, action) => {
        return Object.assign({}, state, {
            [action.stage]: [
                ...state[action.stage],
                action.payload
            ]
        });
    }),
    on(DrugActions.AddStageAction, (state, action) => {
        return Object.assign({}, state, {
            [action.stage]: state[action.stage].map(token => {
                if (token.tokenId === action.payload.tokenId) {
                    let stages = token.stages?.slice() || [];
                    stages.push(action.payload);

                    return Object.assign({}, token, { stages: stages });

                } else {
                    return token;
                }
            })
        })
    })
);
