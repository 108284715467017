import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { AppStore } from "../../store";
import { DEFAULT_BLOCKCHAIN } from "../../shared/utilities/constants";
import { SetBlockChainAction } from "../../user/user.actions";


@Component({
    selector: "customer-loyalty",
    templateUrl: "./customer-loyalty.component.html",
    styleUrls: ["./customer-loyalty.component.scss"]
})

export class CustomerLoyaltyComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    
    public menuItems: any[] = [];
    public hideCardSection: boolean = true;

    constructor(
        private store: Store<AppStore>,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.store.dispatch(SetBlockChainAction(DEFAULT_BLOCKCHAIN));

        this.menuItems = [
            {
                label: "1. Tokenize",
                icon: "mdi mdi-circle-medium",
                routerLink: "rewards_card"
            },
            {
                label: "2. Earn Rewards",
                routerLink: "earn_rewards"
            },
            {
                label: "3. Redeem Rewards",
                routerLink: "redeem_rewards"
            }
        ];

        this.subscriptions.push(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(event => {
                    if ((event as NavigationEnd).url.endsWith("rewards_card")) {
                        this.toggleRewardsCard(true);
                    }
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    toggleRewardsCard(state?: boolean) {
        if (state === undefined) {
            this.hideCardSection = !this.hideCardSection;

        } else {
            this.hideCardSection = !state;
        }
    }
}