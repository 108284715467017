import { createAction, props } from "@ngrx/store";

import {
    RewardsCardModel,
    EarnItemModel,
    RedeemItemModel
} from "../../shared/models";

export const LoadRoyaltyCardAction = createAction(
    "[Customer Loyalty] Load Royalty Card",
    (card: RewardsCardModel) => ({ payload: card })
);

export const AdjustPointsAction = createAction(
    "[Customer Loyalty] Adjust Points",
    (adjustment: number) => ({ payload: adjustment })
);

export const EarnRewardAction = createAction(
    "[Customer Loyalty] Earn Reward",
    (reward: EarnItemModel) => ({ payload: reward })
);

export const RedeemItemAction = createAction(
    "[Customer Loyalty] Redeem Item",
    (item: RedeemItemModel) => ({ payload: item })
);

export const RedeemItemSuccessAction = createAction(
    "[Customer Loyalty] Redeem Item Success",
    (item: RedeemItemModel) => ({ payload: item })
);

export const RedeemItemFailureAction = createAction(
    "[Customer Loyalty] Redeem Item Failure",
    (errString: string) => ({ payload: errString })
)