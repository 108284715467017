import { createSelector } from "@ngrx/store";

import { AppStore } from "../../store";


export const selectCustomerLoyaltyState = (state: AppStore) => state.customerLoyalty;

export const getRewardsCard = createSelector(
    selectCustomerLoyaltyState,
    state => state.rewardsCard
);

export const getCurrentPoints = createSelector(
    selectCustomerLoyaltyState,
    state => state.points
);

export const getRedemptionHistory = createSelector(
    selectCustomerLoyaltyState,
    state => state.redemptionHistory
);