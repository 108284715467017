import { createSelector } from "@ngrx/store";
import { AppStore } from "../store";

export const selectLayoutState = (state: AppStore) => state.layout;

export const getMenuActiveState = createSelector(
    selectLayoutState,
    (state) => state.activeMenu
);

export const getMenuExpandedState = createSelector(
    selectLayoutState,
    state => state.menuExpanded
);

export const getSubmenuState = createSelector(
    selectLayoutState,
    state => state.submenu
);

export const getLoadingState = createSelector(
    selectLayoutState,
    (state) => state.showLoading
);

export const getFullMenuShown = createSelector(
    selectLayoutState,
    state => state.showFullMenu
);

export const getTheme = createSelector(
    selectLayoutState, 
    state => state.theme
);