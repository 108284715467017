<div *ngIf="card" class="reward-card-box">
    <div class="eth-logo-placement" >
        <img class="eth-logo" src="../../../../assets/images/eth-icon.png">
    </div>

    <div class="main-info">
        <div class="info"  *ngFor="let field of info">
            <span class="info-title line">
                {{ field.title }}
            </span>

            <span class="info-value line">
                {{ field.value }}
            </span>
        </div>
    </div>

    <div *ngIf="card" class='points'>
        <div class="point-total">
            {{ points }}<br/>
            Points
        </div>

        <div class="eth-logo-placement" >
            <img class="qr-code" src="../../../../assets/images/qrcode_example.png">
        </div>
    </div>
</div>

<div *ngIf="!card" class="empty-state">
    <h3>
        Sign Up for a Rewards card
    </h3>

    <img src="assets/images/empty-card.png">

    <p>
        Buy and earn points that you can redeem for rewards.
    </p>

    <p-button
        label="Get a Rewards Card"
        routerLink="rewards_card"
    ></p-button>
</div>