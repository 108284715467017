import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormArray, FormControl } from "@angular/forms";
import { SelectItem } from "primeng/api";
import hljs from "highlight.js";


@Component({
    selector: "landing",
    templateUrl: "./landing.component.html",
    styleUrls: ["./landing.component.scss"],
    host: { "demo": "customer-loyalty" }
})
export class LandingComponent implements OnInit, AfterViewInit {

    public steps: any[] = [];
    public codeOptions: SelectItem[] = [];
    public apis: any[] = [];
    public codeControls: FormArray<FormControl>;

    constructor(
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.steps = [
            {
                icon: "mdi-shield-key-outline",
                name: "Tokenize",
                description: "Mint a digital wallet to track and secure loyalty tokens",
                routerLink: "rewards_card"
            },
            {
                icon: "mdi-star-outline",
                name: "Earn Rewards",
                description: "Offer digital token rewards through purchases and other exclusive opportunities",
                routerLink: "earn_rewards"
            },
            { 
                icon: "mdi-check-decagram-outline",
                name: "Redeem Rewards",
                description: "Verify the authenticity of digital tokens and redeem them in exchange for exclusive access to unique rewards.",
                routerLink: "redeem_rewards"
            }
        ];

        this.codeOptions = [
            {
                label: "python",
                value: 0
            }
        ];

        /*
        this.apis = [
            description: "",
            codeBlocks: [
``,
``
            ]
        ];
        */

        this.codeControls = this.fb.array(
            this.apis.map(api => new FormControl(0))
        );
    }

    ngAfterViewInit() {
        setTimeout(() => {
            document.querySelectorAll('pre code').forEach((el) => {
                hljs.highlightElement(el as HTMLElement);
            });
        });
    }

    copyCode(index: number) {
        navigator.clipboard.writeText(
            this.apis[index].codeBlocks[this.codeControls.at(index).value]
        );
    }
}