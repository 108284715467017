import { createAction } from "@ngrx/store";

import { toPayload } from "../../shared/utilities/constants";

export const DataVaultActionTypes = {
    SET_TOKENID: "[DataVault] Set TokenId",
    PDF_MINT: "[DataVault] Mint PDF",
    PDF_MINT_SUCCESS: "[DataVault] Mint PDF Success",
    PDF_MINT_FAILURE: "[DataVault] Mint PDF Failure",
    PDF_VIEW: "[DataVault] View PDF",
    PDF_VIEW_SUCCESS: "[DataVault] View PDF Success",
    PDF_VIEW_FAILURE: "[DataVault] View PDF Failure",
    ADD_STAGE: "[DataVault] Add Stage"
}

export const SetPdfTokenIdAction = createAction(
    DataVaultActionTypes.SET_TOKENID,
    (id: string) => ({ payload: id })
);

export const PdfMint = createAction(
    DataVaultActionTypes.PDF_MINT,
    (data: any) => ({ payload: data })
);

export const PdfMintSuccess = createAction(
    DataVaultActionTypes.PDF_MINT_SUCCESS,
    (res: any) => ({ payload: res })
);

export const PdfMintFail = createAction(
    DataVaultActionTypes.PDF_MINT_FAILURE,
    (err: any) => ({ payload: err })
);

export const viewPdf = createAction(
    DataVaultActionTypes.PDF_VIEW,
    (tokenId: string) => ({ tokenId: tokenId })
);

export const viewPdfSuccess = createAction(
    DataVaultActionTypes.PDF_VIEW_SUCCESS,
    (data: any) => ({payload:data})
);

export const viewPdfFail = createAction(
    DataVaultActionTypes.PDF_VIEW_FAILURE,
    (err: any) => ({payload:err})
);

export const AddStageAction = createAction(
    DataVaultActionTypes.ADD_STAGE,
    toPayload<any>
);