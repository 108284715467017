// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import "zone.js/plugins/zone-error";
declare const require: any;
const { version: appVersion } = require("../../package.json");

export const environment = {
    production: false,
    ttl: 480,
    grant_type: "password",
    scope: "Data DEFAULT",
    access_token_ttl: 1800000,
    oauth_cookie_exp: 86400000, // 1 day
    version: appVersion,
    caching: true,
    cache_ttl: 60000, // Cache expiry time
    state_logger_enabled: false,
    enable_encryption: false,
    authConfig: {
        // Url of the Identity Provider
        issuer: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_ObavuOxng",
        //https://cognito-idp.us-east-1.amazonaws.com/us-east-1_ObavuOxng/.well-known/openid-configuration

        // URL of the SPA to redirect the user to after login
        redirectUri: window.location.origin + "/",

        // The SPA's id. The SPA is registerd with this id at the auth-server
        clientId: "mgs1uf5849flljik5r884iuis",

        // set the scope for the permissions the client should request
        // The first three are defined by OIDC. The 4th is a usecase-specific one
        scope: "openid profile email",

        // Disable https for dev
        requireHttps: false,

        requestAccessToken: true,

        responseType: "code",
        oidc: true,
        strictDiscoveryDocumentValidation: false,
        tokenEndpoint: "https://tokenx-test-user-pool-01.auth.us-east-1.amazoncognito.com/oauth2/token",

        logoutUrl: "https://staging-id.lifesitevault.com/oauth2/sessions/logout"
    }
};
