import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Subscription } from "rxjs";

import { AppStore } from "../../../store";
import { getCurrentPoints, getRewardsCard } from "../customer-loyalty.selectors";
import { RewardsCardModel } from "../../../shared/models";


@Component({
    selector: "rewards-card",
    templateUrl: "./rewards-card.component.html",
    styleUrls: ["./rewards-card.component.scss"]
})
export class RewardsCardDisplay implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    public info: any[];
    public points: number;
    public card: RewardsCardModel;

    constructor(private store: Store<AppStore>) {}

    ngOnInit(): void {
        this.info = [
            {title: "Name", key:"name", value: ""},
            {title: "DoB", key:"dob", value: ""},
            {title: "Phone", key:"phone", value: ""},
            {title: "Merchant", key:"merchant", value: ""},
            {title: "Member Since", key:"startDate", value: ""},
            {title: "Rewards Program", key:"programName", value: ""},
        ];

        this.subscriptions.push(
            this.store.pipe(select(getRewardsCard)).subscribe((card: any) => {
                this.card = card;

                if (card && Object.keys(card).length > 0) {
                    this.info = this.info.map(item => {
                        if (card.hasOwnProperty(item.key)) {
                            item.value = card[item.key];
                        }
                        return item;
                    });
                }
            })
        );

        this.subscriptions.push(
            this.store.select(getCurrentPoints).subscribe(points => {
                this.points = points;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}