import { createAction } from "@ngrx/store";

export const DocAuthActionTypes = {
    SET_TOKENID: "[Doc Auth] Set TokenId",
    PDF_MINT: "[Doc Auth] Mint PDF",
    PDF_MINT_SUCCESS: "[Doc Auth] Mint PDF Success",
    PDF_MINT_FAILURE: "[Doc Auth] Mint PDF Failure",
    PDF_VIEW: "[Doc Auth] View PDF",
    PDF_VIEW_SUCCESS: "[Doc Auth] View PDF Success",
    PDF_VIEW_FAILURE: "[Doc Auth] View PDF Failure"
}

export const SetPdfTokenIdAction = createAction(
    DocAuthActionTypes.SET_TOKENID,
    (id: string) => ({ payload: id })
);

export const PdfMint = createAction(
    DocAuthActionTypes.PDF_MINT,
    (data: any) => ({ payload: data })
);

export const PdfMintSuccess = createAction(
    DocAuthActionTypes.PDF_MINT_SUCCESS,
    (res: any) => ({ payload: res })
);

export const PdfMintFail = createAction(
    DocAuthActionTypes.PDF_MINT_FAILURE,
    (err: any) => ({ payload: err })
);

export const viewPdf = createAction(
    DocAuthActionTypes.PDF_VIEW,
    (tokenId: string) => ({ tokenId: tokenId })
);

export const viewPdfSuccess = createAction(
    DocAuthActionTypes.PDF_VIEW_SUCCESS,
    (data: any) => ({payload:data})
);

export const viewPdfFail = createAction(
    DocAuthActionTypes.PDF_VIEW_FAILURE,
    (err: any) => ({payload:err})
);
