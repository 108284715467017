import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { Store, select } from '@ngrx/store';
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AuthConfig, NullValidationHandler, OAuthService } from "angular-oauth2-oidc";

import { environment } from "../environments/environment";
import { AppStore } from './store';
import { DEMO_LIST } from './shared/utilities/constants';
import { checkUserConnectionAction } from './user/user.actions';
import {
    getLoadingState,
    getMenuExpandedState,
    getSubmenuState,
    getFullMenuShown,
    getTheme
} from "./layout/layout.selectors";
import { SetFullMenuAction } from "./layout/layout.actions";
import { LayoutService } from './shared';
import { fadeAnimation } from './shared/utilities/animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    public showLoadingMask: boolean = false;
    public menuHidden: boolean = false;
    public menuExpanded: boolean = true;
    public showSubmenu: boolean = false;
    public showFullMenu: boolean = false;
    public currentTheme: string = "dark";

    constructor(
        private store: Store<AppStore>,
        private router: Router,
        private layoutService: LayoutService,
        private oAuthService: OAuthService
    ) {
        // Ensure oAuthConfig gets set correctly, even on refresh
        const noDiscoveryAuthConfigObj: AuthConfig = environment.authConfig;
        this.oAuthService.configure(noDiscoveryAuthConfigObj);
        this.oAuthService.setStorage(sessionStorage);
        this.oAuthService.tokenValidationHandler = new NullValidationHandler();
    }

    ngOnInit(): void {
        this.store.dispatch(checkUserConnectionAction());

        this.subscriptions.push(
            this.store.pipe(select(getLoadingState)).subscribe(loading => {
                this.showLoadingMask = loading;
            })
        );

        this.subscriptions.push(
            this.store.select(getMenuExpandedState).subscribe(expanded => {
                this.menuExpanded = expanded;
            })
        );

        this.subscriptions.push(
            this.store.select(getSubmenuState).subscribe(submenu => {
                this.showSubmenu = submenu;
            })
        );

        this.subscriptions.push(
            this.store.select(getFullMenuShown).subscribe(shown => {
                this.showFullMenu = shown;
            })
        );

        this.subscriptions.push(
            this.store.select(getTheme).subscribe(theme => {
                this.currentTheme = theme;

                this.layoutService.switchTheme(theme);
            })
        );

        this.subscriptions.push(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(event => {
                    const hideMenuPages = DEMO_LIST
                        .filter(demo => demo.submenuOnly)
                        .map(demo => demo.routerLink);

                    if (hideMenuPages.some(route => (event as NavigationEnd).url.startsWith(route))) {
                        // Hide left nav
                        this.menuHidden = true;

                    } else {
                        this.menuHidden = false;
                    }

                    this.store.dispatch(SetFullMenuAction(false));
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }
}
