import { createReducer, on } from "@ngrx/store";
import * as BloodSampleActions from "./blood-sample.actions";


export interface PdfMetadataModel {
    hash: string;
    encoding?: string;
    md5: string;
    mimetype?: string;
    size: number;
}

export interface BloodSampleState {
    pdfHash: string;
    tokenId: string;
    data: any[];
}

const initialState: BloodSampleState = <BloodSampleState>{
    pdfHash: null,
    tokenId: null,
    data: []
};

export const bloodSampleReducer = createReducer(
    initialState,
    on(BloodSampleActions.SetPdfTokenIdAction, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload
        });
    }),
    on(BloodSampleActions.PdfMintSuccess, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload.events.Transfer.returnValues.tokenId
        });
    }),
    on(BloodSampleActions.AddStageAction, (state, action) => {
        return Object.assign({}, state, {
            bottles: state.data.map(gem => {
                if (gem.tokenId === action.payload.tokenId) {
                    let stages = gem.stages || [];
                    stages.push(action.payload);

                    return Object.assign({}, gem, { stages: stages });

                } else {
                    return gem;
                }
            })
        })
    })
);
