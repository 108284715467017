<div class="page-title">
    Tokenize
</div>

<div class="form-con">
    <form [formGroup]="form">
        <div class="input-row">
            <label>
                Customer Name
            </label>
    
            <input
                pInputText type="text"
                formControlName="name"
                placeholder="Customer Name"
            />
        </div>
    
        <div class="input-row">
            <label>
                Date of Birth
            </label>
    
            <input
                pInputText type="text"
                formControlName="dob"
                placeholder="Date of Birth"
            />
        </div>
    
        <div class="input-row">
            <label>
                Phone
            </label>
    
            <input
                pInputText type="text"
                formControlName="phone"
                placeholder="Phone"
            />
        </div>
    
        <div class="input-row">
            <label>
                Merchant
            </label>
    
            <p-dropdown
                appendTo="body"
                formControlName="merchant"
                placeholder="Merchant"
                [options]="merchantOptions"
            ></p-dropdown>
        </div>
    
        <div class="input-row">
            <label>
                Member Since
            </label>
    
            <input
                pInputText type="text"
                formControlName="startDate"
                placeholder="Member Since"
            />
        </div>
    
        <div class="input-row">
            <label>
                Rewards Program
            </label>
    
            <p-dropdown
                appendTo="body"
                formControlName="programName"
                placeholder="Rewards Program"
                [options]="programOptions"
            ></p-dropdown>
        </div>
    </form>
</div>

<div class="web3-actions-con">
    <div class="section-title">
        Web3 End User API
    </div>

    <p-button
        label="Set Parameters"
        (onClick)="setWeb3Params()"
    ></p-button>
    <br/><br/>
    <p-button
        label="Mint Rewards Card"
        (onClick)="mintRewardsCard()"
    ></p-button>
</div>

<div class="debug-con" hidden="true">
    <h5>Debug</h5>
    <p>Metadata URI: {{ metadataUri }}</p>
    <p>Token ID: {{ tokenId }}</p>
</div>