import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { firstValueFrom } from 'rxjs';
import { omit } from "lodash";

import { AppStore } from "../store";
import populateData from "./auto_populate.json";
import { TokenService } from '../shared/services/token.service';
import { EmitAlertAction } from '../shared/components/alerts/alerts.actions';
import { ShowLoadingMaskAction, HideLoadingMaskAction } from '../layout';


@Component({
  selector: 'app-create-token',
  templateUrl: './create-token.component.html',
  styleUrls: ['./create-token.component.scss']
})
export class CreateTokenComponent implements OnInit {
    public infoPage: boolean = false;
    public selectedOption: string;
    public manualAddress: string;
    public fileName: string;
    public formSchema: any[] = [];
    public infoForm: FormGroup;
    public yesNoOptions: any[] = [];
    public typeOptions: any[] = [];
    public storageOptions: any[] = [];

    private currentType: string;
    private filehash: string;
    private metadataHash: string;

    constructor(
        private store: Store<AppStore>,
        private cdr: ChangeDetectorRef,
        private tokenService: TokenService
    ) {}

    ngOnInit() {
        this.yesNoOptions = [
            {
                label: "Yes",
                value: true
            },
            {
                label: "No",
                value: false
            }
        ];

        this.typeOptions = [
            {
                label: "Authentication",
                image: "assets/images/icons/description.png",
                value: "auth"
            },
            {
                label: "Event Ticket",
                image: "assets/images/icons/local_activity_dark.png",
                value: "event"
            },
            {
                label: "Chain of Custody",
                image: "assets/images/icons/conveyor_belt.png",
                value: "custody"
            },
            {
                label: "Supply Chain",
                image: "assets/images/icons/conveyor_belt.png",
                value: "supply"
            },
            {
                label: "Asset Management",
                image: "assets/images/icons/description.png",
                value: "asset"
            },
            {
                label: "Health Data",
                image: "assets/images/icons/ecg_heart.png",
                value: "health"
            },
            // {
            //     label: "Flex",
            //     image: "",
            //     value: "flex"
            // }
        ];

        this.storageOptions = [
            {
                label: "Web2",
                value: "web2"
            },
            {
                label: "Web3",
                value: "web3"
            },
            {
                label: "Database",
                value: "database"
            },
            {
                label: "Custom API",
                value: "custom"
            }
        ];

        this.buildForm("flex");
    }

    buildForm(type: string) {
        switch(type) {
            case "auth": {
                this.formSchema = [
                    {
                        key: "nft_name",
                        type: "text",
                        label: "NFT Name"
                    },
                    {
                        key: "nft_desc",
                        type: "text",
                        label: "NFT Desc"
                    },
                    {
                        key: "ID",
                        type: "text",
                        label: "ID"
                    },
                    {
                        key: "date",
                        type: "date",
                        label: "Date"
                    },
                    {
                        key: "AuthType",
                        type: "select",
                        label: "Auth Type",
                        options: [
                            {
                                label: "User",
                                value: "User"
                            },
                            {
                                label: "Device",
                                value: "Device"
                            },
                            {
                                label: "Document (i.e. Digital Will)",
                                value: "DocDigitalWill"
                            },
                            {
                                label: "Credential",
                                value: "Credential"
                            },
                            {
                                label: "Certification",
                                value: "Certification"
                            },
                            {
                                label: "Good or Service",
                                value: "GoodOrService"
                            },
                            {
                                label: "Media",
                                value: "Media"
                            }
                        ]
                    },
                    {
                        key: "file",
                        type: "file",
                        label: "Document",
                        conditional: {
                            key: "AuthType",
                            value: "DocDigitalWill"
                        }
                    },
                    {
                        key: "doc_storage",
                        type: "select",
                        label: "",
                        placeholder: "Select Storage",
                        conditional: {
                            key: "AuthType",
                            value: "DocDigitalWill"
                        },
                        options: this.storageOptions
                    },
                    {
                        key: "SubjectID",
                        type: "text",
                        label: "Subject ID",
                        description: "(user, device, document, etc)",
                    },
                    {
                        key: "CryptographicSignature",
                        type: "text",
                        label: "Cryptographic Signature"
                    },
                    {
                        key: "Updatable",
                        type: "select",
                        label: "Updateable",
                        options: this.yesNoOptions
                    },
                    {
                        key: "Encrypt",
                        type: "select",
                        label: "Encrypt",
                        options: this.yesNoOptions
                    }
                ];
                break;
            }

            case "event": {
                this.formSchema = [
                    {
                        key: "nft_name",
                        type: "text",
                        label: "NFT Name"
                    },
                    {
                        key: "nft_desc",
                        type: "text",
                        label: "NFT Desc"
                    },
                    {
                        key: "ID",
                        type: "text",
                        label: "ID"
                    },
                    {
                        key: "DateOfPurchase",
                        type: "date",
                        label: "Date of Purchase"
                    },
                    {
                        key: "Price",
                        type: "text",
                        label: "Price"
                    },
                    {
                        key: "EventName",
                        type: "text",
                        label: "Event Name"
                    },
                    {
                        key: "Ticket Type",
                        type: "text",
                        label: "Ticket Type"
                    },
                    {
                        key: "DateOfEvent",
                        type: "date",
                        label: "Date of Event"
                    },
                    {
                        key: "Location",
                        type: "text",
                        label: "Location"
                    },
                    {
                        key: "Transferable",
                        type: "select",
                        label: "Transferable",
                        options: this.yesNoOptions
                    },
                    {
                        key: "Fixed Price",
                        type: "select",
                        label: "Fixed Price",
                        options: this.yesNoOptions
                    },
                    {
                        key: "Encrypt",
                        type: "select",
                        label: "Encrypt",
                        options: this.yesNoOptions
                    }
                ];
                break;
            }

            case "custody": {
                this.formSchema = [
                    {
                        key: "nft_name",
                        type: "text",
                        label: "NFT Name"
                    },
                    {
                        key: "nft_desc",
                        type: "text",
                        label: "NFT Desc"
                    },
                    {
                        key: "ID",
                        type: "text",
                        label: "ID"
                    },
                    {
                        key: "date",
                        type: "date",
                        label: "Date"
                    },
                    {
                        key: "CaseID",
                        type: "text",
                        label: "Case ID"
                    },
                    {
                        key: "ItemID",
                        type: "text",
                        label: "Item ID"
                    },
                    {
                        key: "UserID",
                        type: "text",
                        label: "User ID"
                    },
                    {
                        key: "Expiration",
                        type: "date",
                        label: "Expiration"
                    },
                    {
                        key: "ReportingURL",
                        type: "text",
                        label: "Reporting URL"
                    },
                    {
                        key: "Encrypt",
                        type: "select",
                        label: "Encrypt",
                        options: this.yesNoOptions
                    }
                ];
                break;
            }
            
            case "supply": {
                this.formSchema = [
                    {
                        key: "nft_name",
                        type: "text",
                        label: "NFT Name"
                    },
                    {
                        key: "nft_desc",
                        type: "text",
                        label: "NFT Desc"
                    },
                    {
                        key: "ID",
                        type: "text",
                        label: "ID"
                    },
                    {
                        key: "date",
                        type: "date",
                        label: "Date"
                    },
                    {
                        key: "SerialNumber",
                        type: "text",
                        label: "Serial Number"
                    },
                    {
                        key: "CryptographicChallenge",
                        type: "text",
                        label: "Cryptographic Challenge"
                    },
                    {
                        key: "Encrypt",
                        type: "select",
                        label: "Encrypt",
                        options: this.yesNoOptions
                    }
                ];
                break;
            }
            
            case "asset": {
                this.formSchema = [
                    {
                        key: "nft_name",
                        type: "text",
                        label: "NFT Name"
                    },
                    {
                        key: "nft_desc",
                        type: "text",
                        label: "NFT Desc"
                    },
                    {
                        key: "ID",
                        type: "text",
                        label: "Asset ID"
                    },
                    {
                        key: "AssetDescription",
                        type: "text",
                        label: "Asset Description"
                    },
                    {
                        key: "SerialNumber",
                        type: "text",
                        label: "Serial Number"
                    },
                    {
                        key: "Manufacturer",
                        type: "text",
                        label: "Manufacturer"
                    },
                    {
                        key: "Model",
                        type: "text",
                        label: "Model"
                    },
                    {
                        key: "Location",
                        type: "text",
                        label: "Location"
                    },
                    {
                        key: "DateOfPurchase",
                        type: "text",
                        label: "Date of Purchase"
                    },
                    {
                        key: "Value",
                        type: "text",
                        label: "Value"
                    },
                    {
                        key: "Condition",
                        type: "text",
                        label: "Condition"
                    }
                ];
                break;
            }
            
            case "health": {
                this.formSchema = [
                    {
                        key: "nft_name",
                        type: "text",
                        label: "NFT Name"
                    },
                    {
                        key: "nft_desc",
                        type: "text",
                        label: "NFT Desc"
                    },
                    {
                        key: "date",
                        type: "date",
                        label: "Date"
                    },
                    {
                        key: "PatientID",
                        type: "text",
                        label: "Patient ID"
                    },
                    {
                        key: "RequestID",
                        type: "text",
                        label: "Request ID"
                    },
                    {
                        key: "EmrUrl",
                        type: "text",
                        label: "EMR URL"
                    },
                    {
                        key: "Encrypt",
                        type: "select",
                        label: "Encrypt",
                        options: this.yesNoOptions
                    }
                ];
                break;
            }
            
            case "flex":
            default: {
                this.formSchema = [
                    {
                        key: "nft_name",
                        type: "text",
                        label: "Name"
                    },
                    {
                        key: "nft_desc",
                        type: "text",
                        label: "NFT Desc"
                    },
                    {
                        key: "ID",
                        type: "text",
                        label: "ID"
                    },
                    {
                        key: "date",
                        type: "date",
                        label: "Date"
                    }
                ];
                break;
            }
        }

        let typeControl = new FormControl<string>(type, Validators.required);
        this.infoForm = new FormGroup(
            Object.assign(
                { nft_type: typeControl },
                ...this.formSchema.map(field =>
                    ({ [field.key]: new FormControl("", Validators.required) }))
            )
        );

        typeControl.registerOnChange((type: string) => {
            if (type !== this.currentType) {
                this.currentType = type;
                this.buildForm(type);
            }
        });

        this.clearVariables();
        this.cdr.markForCheck();
    }

    clearVariables() {
        this.filehash = "";
        this.metadataHash = "";
    }

    setOption(option: string) {
        this.selectedOption = option;
    }

    typeTitle(key: string) {
        let option = this.typeOptions.find(option => option.value === key);
        return option ? option.label : "Flex";
    }

    selectType(key: string) {
        this.infoForm.patchValue({
            nft_type: key
        });
    }

    displayInfoForm() {
        if (this.selectedOption === "server-self") {
            if (this.manualAddress) {
                this.infoPage = true;

            } else {
                this.store.dispatch(EmitAlertAction({
                    message: "Please specify the client's wallet address to proceed.",
                    alert_type: "info"
                }));
            }

        } else if (this.selectedOption) {
            this.infoPage = true;
            // this.infoForm.patchValue({ nft_type: "flex" });

        } else {
            this.store.dispatch(EmitAlertAction({
                message: "Please select a wallet option to proceed.",
                alert_type: "info"
            }));
        }
    }

    autoPopulateInfo() {
        let type = this.infoForm.value["nft_type"];

        if (!Object.keys(populateData).includes(type)) {
            this.store.dispatch(EmitAlertAction({
                message: `Token Type "${type}" not supported for Auto Populate`,
                alert_type: "warn"
            }));
            return;
        }

        let example_array: any[] = populateData[type as keyof typeof populateData];
        const index = Math.floor(Math.random() * example_array.length);
        let example_data = example_array[index];
        
        this.infoForm.patchValue(example_data);
    }

    createToken() {
        if (!this.infoForm.valid) {
            this.store.dispatch(EmitAlertAction({
                message: "Please fill all fields and try again.",
                alert_type: "warn"
            }));

            return;
        }

        this.store.dispatch(ShowLoadingMaskAction());

        this.uploadData().then((hash) => {
            return this.uploadToken().then(result => {
                this.store.dispatch(HideLoadingMaskAction());
            });

        }).catch((err) => {
            console.log("Error uploading data:", err);
            this.store.dispatch(HideLoadingMaskAction());
        });
    }

    onFileInput($event:any) {
        this.fileName = $event.target.files[0].name
        this.infoForm.patchValue({file:$event.target.files[0]})
    }

    uploadData() {
        return firstValueFrom(
            this.tokenService.uploadRawData(omit(this.infoForm.value, [
                "filehash", "filehash-anchor"
            ]))
        ).then((hash) => {
            this.filehash = hash;

            return firstValueFrom(
                this.tokenService.uploadMetadata({
                    "nft-name": this.infoForm.value["nft_name"],
                    "nft-desc": this.infoForm.value["nft_desc"],
                    "nft-type": this.infoForm.value["nft_type"],
                    filehash: this.filehash
                })
            ).then((ret) => {
                this.metadataHash = ret["path"];

                this.store.dispatch(EmitAlertAction({
                    message: "NFT Metadata added to IPFS.",
                    alert_type: "success"
                }));

                return this.metadataHash;

            }).catch((err) => {
                this.store.dispatch(EmitAlertAction({
                    message: "Upload NFT MetaData failed. Please try again later.",
                    alert_type: "error"
                }));
                console.log("Metadata upload err:", err);
            });

        }).catch((err) => {
            this.store.dispatch(EmitAlertAction({
                message: "Upload NFT data failed. Please try again later.",
                alert_type: "error"
            }));
            console.log("Raw data err:", err);
        });
    }

    async uploadToken(): Promise<any> {
        switch(this.selectedOption) {
            case "client-self": {
                if (!this.filehash) {
                    this.store.dispatch(EmitAlertAction({
                        message: "Filehash not found. Please try again later.",
                        alert_type: "error"
                    }));

                } else if (!this.metadataHash) {
                    this.store.dispatch(EmitAlertAction({
                        message: "Metadatahash not found. Please try again later.",
                        alert_type: "error"
                    }));

                } else {
                    // TODO: Start loading screen
                    return this.tokenService.mintClientWallet(this.metadataHash);
                }

                break;
            }

            case "server-self": {
                if (!this.filehash) {
                    this.store.dispatch(EmitAlertAction({
                        message: "Filehash not found. Please try again later.",
                        alert_type: "error"
                    }));

                } else if (!this.metadataHash) {
                    this.store.dispatch(EmitAlertAction({
                        message: "Metadatahash not found. Please try again later.",
                        alert_type: "error"
                    }));

                } else {
                    // TODO: Start loading screen
                    return firstValueFrom(
                        this.tokenService.mintServerSelfCustody(
                            this.manualAddress,
                            this.metadataHash
                        )
                    ).then((data) => {
                        this.store.dispatch(EmitAlertAction({
                            message: "Server Mint success!",
                            alert_type: "success"
                        }));
                        console.log("Server mint-self:", data);

                    }).catch((err) => {
                        this.store.dispatch(EmitAlertAction({
                            message: "Server Mint self failed. Please try again later.",
                            alert_type: "error"
                        }));
                        console.log("Server self custodian err:", err);
                    });
                }

                break;
            }

            case "custodian":
            default: {
                if (!this.filehash) {
                    this.store.dispatch(EmitAlertAction({
                        message: "Filehash not found. Please try again later.",
                        alert_type: "error"
                    }));

                } else if (!this.metadataHash) {
                    this.store.dispatch(EmitAlertAction({
                        message: "Metadatahash not found. Please try again later.",
                        alert_type: "error"
                    }));

                } else {
                    // TODO: Start loading screen
                    return firstValueFrom(
                        this.tokenService.mintCustodian(this.metadataHash)
                    ).then((data) => {
                        this.store.dispatch(EmitAlertAction({
                            message: "Server Mint success!",
                            alert_type: "success"
                        }));
                        console.log("Server mint custodian:", data);

                    }).catch((err) => {
                        this.store.dispatch(EmitAlertAction({
                            message: "Server Mint failed. Please try again later.",
                            alert_type: "error"
                        }));
                        console.log("Server custodian mint err:", err);
                    });
                }

                break;
            }
        }

        return Promise.resolve();
    }
}
