export const MOBILE_CUTOFF_WIDTH: number = 640; // in px

export const enum RequestMethod {
    "POST",
    "GET",
    "PUT",
    "PATCH",
    "DELETE"
};

export const enum ResponseType {
    "BLOB" = "blob",
    "ARRAY_BUFFER" = "arraybuffer",
    "TEXT" = "text"
};

export const INFURA_BASE_URL = "https://ls.infura-ipfs.io/ipfs/";
export const CONTRACT_ABI_JSON = "/contracts/LSTokenX.json";

export class BlockchainModel {
    name: string;
    chainId: string;
    coin: string;
    coin_short: string;
    icon?: string;
    url?: string;
    tokenUrl?: string;
    selectable: boolean = false;
};
export const DEFAULT_BLOCKCHAIN = "421614";
export const BLOCKCHAIN_OPTIONS: BlockchainModel[] = [
    {
        name: "Arbitrum Testnet",
        chainId: "421614",
        coin: "Ether",
        coin_short: "ETH",
        icon: "assets/images/arbitrum-icon.png",
        url: "https://sepolia.arbiscan.io",
        tokenUrl: "https://sepolia.arbiscan.io/token/0x4a1D383eCE83C62854673d9D0A64B1efC4161951?a=",
        selectable: true
    },
    {
        name: "Ethereum Mainnet",
        chainId: "1",
        coin: "Ethereum",
        coin_short: "ETH",
        icon: "assets/images/eth-icon.png",
        url: "",
        tokenUrl: "",
        selectable: false
    },
    {
        name: "Ethereum Testnet",
        chainId: "11155111",
        coin: "Ethereum",
        coin_short: "ETH",
        icon: "assets/images/eth-icon.png",
        url: "https://sepolia.etherscan.io",
        tokenUrl: "https://sepolia.etherscan.io/nft/0x4a1d383ece83c62854673d9d0a64b1efc4161951/",
        selectable: true
    },
    {
        name: "Polygon Mainnet",
        chainId: "137",
        coin: "MATIC",
        coin_short: "MATIC",
        icon: "assets/images/polygon-icon.png",
        url: "",
        tokenUrl: "",
        selectable: false
    },
    {
        name: "Polygon Testnet",
        chainId: "80001",
        coin: "MATIC",
        coin_short: "MATIC",
        icon: "assets/images/polygon-icon.png",
        url: "https://mumbai.polygonscan.com",
        tokenUrl: "https://mumbai.polygonscan.com/token/0x62dbbedd28d2cc3a3c05e39dc97da8aca50e06d2/",
        selectable: false
    },
    {
        name: "Optimism Testnet",
        chainId: "11155420",
        coin: "Ether",
        coin_short: "ETH",
        icon: "assets/images/icons/optimism-logo.png",
        url: "https://sepolia-optimistic.etherscan.io",
        tokenUrl: "https://sepolia-optimistic.etherscan.io/address/0x4a1D383eCE83C62854673d9D0A64B1efC4161951?a=",
        selectable: true
    },
    {
        name: "Polygon Testnet",
        chainId: "80002",
        coin: "MATIC",
        coin_short: "MATIC",
        icon: "assets/images/polygon-icon.png",
        url: "https://www.oklink.com/amoy",
        tokenUrl: "https://www.oklink.com/amoy/assets/0x02df48be44a51ceb0c05edc2552e66bda3309146/",
        selectable: true
    }
];

export function formNFTLink(tokenId: string, chainId: string) {
    let chain = BLOCKCHAIN_OPTIONS.find(option => option.chainId === chainId)
        || BLOCKCHAIN_OPTIONS.filter(chain => chain.selectable)[0];
    return chain.tokenUrl + tokenId;
}

export const DEFAULT_STARTING_POINTS = 1000;

export function toPayload<T> (arg: T) {
    return { payload: arg };
};

export const DEMO_LIST: any[] = [
    {
        image_url: "assets/images/demos/customer-loyalty/award_star.png",
        name: "Customer Loyalty",
        fullName: "Customer Loyalty Rewards",
        titleIcon: "mdi-laptop",
        description: "Connect your existing Web2 customer loyalty program to a Web3 counterpart, establishing a robust system where users can securely earn and redeem digital tokens in exchange for exclusive access to unique rewards.",
        routerLink: "/demos/customer_loyalty"
    },
    {
        image_url: "assets/images/demos/pdf-tokenizer/note.png",
        name: "Crypto-Seal Documents",
        titleIcon: "mdi-laptop",
        description: "Physically scan hard copies of important/legal documents to digitize them and embed ownership-related metadata on the blockchain all in one step. Establish an unalterable proof of ownership and authenticity that is transferable and verifiable.",
        routerLink: "/demos/pdf_tokenizer",
        submenuOnly: false
    },
    {
        image_url: "assets/images/demos/doc-auth/description.png",
        name: "Document Authentication",
        titleIcon: "mdi-laptop",
        description: "Embed crucial documents' ownership-related metadata on the blockchain to establish an unalterable verification of ownership and authenticity.",
        routerLink: "/demos/doc_auth"
    },
    {
        image_url: "assets/images/demos/rare-whiskey/liquor.png",
        name: "Rare Whiskey",
        titleIcon: "mdi-laptop",
        description: "Create a digital token with unique blend and maturation details for barrels and whiskey, physically embed it on bottles, and use it to confirm product authenticity during sales and ownership transfers.",
        routerLink: "/demos/rare_whiskey"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "Supply Chain",
        titleIcon: "mdi-laptop",
        description: "Embed authenticity attributes such as serial numbers and product certifications to a token which can be physically embedded to projects and tracked through its lifecycle through the supply chain.",
        routerLink: "/demos/safety_certification"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "Gemstones",
        titleIcon: "mdi-laptop",
        description: "Create a verifiable digital record for item ownership and authenticity.",
        routerLink: "/demos/gemstones"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "ID & Health Records",
        titleIcon: "mdi-laptop",
        description: "Tokenize personal identity information and health records to ensure a tamper-proof, verifiable, and easily accessible record to use for validation and prevention of unauthorized modifications.",
        routerLink: "/demos/data_vault"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "Supply Chain - Coffee Beans",
        titleIcon: "mdi-laptop",
        description: "Track the entire coffee bean supply chain with a single non-fungible token, ensuring every step from farm to cup is tamper-proof, verifiable, and easily accessible. Enhance transparency, authenticity, and efficiency.",
        routerLink: "/demos/coffee"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "Chain of Custody - Blood Samples",
        titleIcon: "mdi-laptop",
        description: "Track the entire chain of custody for blood samples with a single non-fungible token, ensuring every step from collection to analysis is tamper-proof, verifiable, and easily accessible. Enhance transparency, authenticity, and efficiency.",
        routerLink: "/demos/blood_sample"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "Event Ticketing",
        titleIcon: "mdi-laptop",
        description: "Tokenize, View/Download, Validate",
        routerLink: "/demos/tickets"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "Diploma Authentication",
        titleIcon: "mdi-laptop",
        description: "Create a single non-fungible digital token to represent a student's complete academic record including transcripts, diplomas, and certificates. This makes the documents tamper-proof, verifiable, and easily accessible. Students and alumni can permanently access their records, while employers and public sector entities can efficiently validate education qualifications. This streamlined process ensures authenticity, prevents forgeries, and simplifies verification.",
        routerLink: "/demos/diploma"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "Art Authentication",
        titleIcon: "mdi-laptop",
        description: "Embed crucial documents' ownership-related metadata on the blockchain to establish an unalterable verification of ownership and authenticity.",
        routerLink: "/demos/art"
    },
    {
        image_url: "assets/images/demos/safety-certification/description.png",
        name: "Drug Track & Trace",
        titleIcon: "mdi-laptop",
        description: "Ensure pharmaceutical integrity by tokenizing each stage of a drug’s lifecycle, from regulatory approval to patient consumption. Every step is recorded on the blockchain, providing transparency, security, and tamper-proof verification. As drugs move through the supply chain, from manufacturing to packaging, tokens link together detailed records to create an immutable history of the medication.",
        routerLink: "/demos/drug"
    }
];