<div class="sign-code-container">
    Sign Code:
    <input pInputText id="msg-to-sign"
        type="text"
        [(ngModel)]="exampleSignCode"
        placeholder="Sign this message"
    />

    <br/><br/>

    <p-button class="ui-button ui-widget ui-corner-all"
        id="btn-get-wallet-nfts"
        (click)="fetchTokens()"
    >
        List Tokens
    </p-button>
</div>

<div *ngFor="let nft of nfts" class='tile-container'>
    <div class='img-container'>
        <img class='clsNftPreviewImg' [src]="nft.image || 'assets/images/Lifesite_logo.svg'"/>
    </div>

    <div class='title'>
        {{ nft.name }}
    </div>

    <div class='tile-sub-text'>
        Desc: {{ nft.description }}
    </div>

    <div class='tile-sub-text'>
        <div>
            Token Id:
            <a target='_new' href="https://Sepolia.etherscan.io/nft/{{nft.token_address}}/{{nft.token_id}}">
                {{ nft.token_id }}
            </a>
        </div>

        <div>
            Token-Type: {{ nft.nft_type | uppercase }}
        </div>

        <div class='clsNftRawDataContainer' [id]="nft?.nft_raw_data">
            <ng-container [ngSwitch]="nft?.raw_data?.nft_type | uppercase">
                <div *ngSwitchCase="'AUTH'">
                    <div>Auth ID: {{ nft.raw_data["ID"] }}</div>
                    <div>Subject ID: {{ nft.raw_data["SubjectID"] }}</div>
                    <div>Signature: {{ nft.raw_data["CryptographicSignature"] }}</div>
                    <!-- <div>
                        <a href="{{ nft.raw_data['nftDocUri'] }}" target="_new">
                            View NFT Doc
                        </a>
                    </div> -->
                </div>

                <div *ngSwitchCase="'CUSTODY'">
                    <div>Case ID: {{ nft.raw_data["CaseID"] }}</div>
                    <div>Item ID: {{ nft.raw_data["ItemID"] }}</div>
                    <div>User ID: {{ nft.raw_data["UserID"] }}</div>
                    <!-- <div>
                        <a href="{{ nft.raw_data['nftDocUri'] }}" target="_new">
                            View NFT Doc
                        </a>
                    </div> -->
                </div>

                <div *ngSwitchCase="'EVENT'">
                    <div>Event Name: {{ nft.raw_data["EventName"] }}</div>
                    <div>Date of Event: {{ nft.raw_data["DateOfEvent"] }}</div>
                    <div>Location: {{ nft.raw_data["Location"] }}</div>
                    <!-- <div>
                        <a href="{{ nft.raw_data['nftDocUri'] }}" target="_new">
                            View NFT Doc
                        </a>
                    </div> -->
                </div>

                <div *ngSwitchCase="'SUPPLY'">
                    <div>ID: {{ nft.raw_data["ID"] }}</div>
                    <div>Date: {{ nft.raw_data["date"] }}</div>
                    <div>Serial Number: {{ nft.raw_data["SerialNumber"] }}</div>
                    <!-- <div>
                        <a href="{{ nft.raw_data['nftDocUri'] }}" target="_new">
                            View NFT Doc
                        </a>
                    </div> -->
                </div>

                <div *ngSwitchDefault>
                    N/A
                </div>
            </ng-container>
        </div>
    </div><br>

    <div class='watermark'>
        LifeSite Testnet NFT
    </div>

    <div>
        <a target='_new' href='https://Sepolia.etherscan.io/token/{{nft.token_address}}'>
            View on Ethereum Blockchain
        </a>
    </div>
</div>
