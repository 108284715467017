import { createAction, props } from "@ngrx/store";

export const EmitAlertAction = createAction(
    "[Alerts] Emit Alert",
    props<{
        message: string,
        alert_type: string,
        ttl?: number,
        id?: number
        sticky?: boolean
    }>()
)

export const CloseAlertAction = createAction(
    "[Alerts] Close Alert",
    props<{id:number}>()
)