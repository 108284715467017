
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription, firstValueFrom } from "rxjs";

import { AppStore } from "../../../store";
import { RedeemItemModel, RewardsCardModel } from "../../../shared/models";
import { BLOCKCHAIN_OPTIONS } from "../../../shared/utilities/constants";
import { EmitAlertAction } from "../../../shared/components/alerts";
import { getBlockchain } from "../../../user/user.selectors";
import { getRewardsCard, getRedemptionHistory } from "../customer-loyalty.selectors";
import { CustomerLoyaltyService } from "../customer-loyalty.service";


@Component({
    selector: "rewards-tracker",
    templateUrl: "./rewards-tracker.component.html",
    styleUrls: ["./rewards-tracker.component.scss"]
})

export class RewardsTracker implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private rewardsCard: RewardsCardModel;

    public blockchainUrl: string;
    public redeemed: RedeemItemModel[] = [];
    public rewardsHistory: any[] = [];

    constructor(
        private store: Store<AppStore>,
        private service: CustomerLoyaltyService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(getBlockchain).subscribe(chainId => {
                let blockchain = BLOCKCHAIN_OPTIONS.find(chain => chain.chainId === chainId);
                if (blockchain) {
                    this.blockchainUrl = blockchain.url;
                }
            })
        );

        this.subscriptions.push(
            this.store.select(getRewardsCard).subscribe(card => {
                this.rewardsCard = card;
            })
        );

        this.subscriptions.push(
            this.store.select(getRedemptionHistory).subscribe(history => {
                this.redeemed = history;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    trackRewards() {
        if (!this.rewardsCard || !this.rewardsCard.tokenId) {
            this.store.dispatch(EmitAlertAction({
                message: "Please mint a Rewards Card first.",
                alert_type: "info"
            }));
            return;
        }

        firstValueFrom(
            this.service.trackRewardsInfo(this.rewardsCard.tokenId)
        ).then(response => {
            this.store.dispatch(EmitAlertAction({
                message: "Tracker info successfully retrieved.",
                alert_type: "success"
            }));

            this.rewardsHistory = response;

        }).catch(err => {
            console.log("Error fetching rewards info:", err);

            this.store.dispatch(EmitAlertAction({
                message: "There was an error fetching your rewards info. Please try again later.",
                alert_type: "error"
            }));
        });
    }
}