<div class="main-left-nav">
    <div class="switcher-con">
        <p-dropdown
            [(ngModel)]="selectedChain"
            [options]="blockchainOptions"
            dataKey="chainId"
            optionLabel="name"
            optionValue="chainId"
            [placeholder]="menuExpanded ? 'Select Blockchain' : ''"
            [autoDisplayFirst]="false"
            appendTo="body"
            [style]="{ 'width': '100%', 'min-width': '0px' }"
            (onChange)="changeBlockchain($event)"
        >
            <ng-template let-chain pTemplate="selectedItem">
                <div *ngIf="menuExpanded"
                    class="dropdown-item selected"
                >
                    <img [src]="chain.icon">
                    <div>{{ chain.name }}</div>
                </div>

                <div *ngIf="!menuExpanded"
                    class="dropdown-item selected short"
                >
                    <img [src]="chain.icon">
                </div>
            </ng-template>

            <ng-template let-chain pTemplate="item">
                <div class="dropdown-item">
                    <img [src]="chain.icon">
                    <div>{{ chain.name }}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>

    <div class="static-menu">
        <p-menu *ngIf="menuExpanded" [model]="menuItems"></p-menu>

        <div *ngIf="!menuExpanded" class="collapsed-menu">
            <div *ngFor="let item of menuItems" class="item-con">
                <i class="mdi {{ item.collapsedIcon }} parent-icon"
                    [class.pointer]="item.routerLink"
                    [pTooltip]="item.collapsedLabel"
                    tooltipPosition="right"
                    [routerLink]="item.routerLink"
                ></i>

                <div *ngFor="let child of (item.items || [])" class="child-con">
                    <i class="mdi {{ child.collapsedIcon }}"
                        [class.pointer]="child.routerLink"
                        [pTooltip]="child.collapsedLabel"
                        tooltipPosition="right"
                        [routerLink]="child.routerLink"
                    ></i>
                </div>
            </div>
        </div>
    </div>

    <div class="extended-menu">
        <p-panelMenu *ngIf="menuExpanded" [model]="panelItems"></p-panelMenu>

        <div *ngIf="!menuExpanded" class="collapsed-extended">
            <div *ngFor="let item of panelItems" class="menu-group">
                <i class="mdi {{ item.icon }} parent-icon"
                    [class.pointer]="item.routerLink"
                    [pTooltip]="item.label"
                    tooltipPosition="right"
                    [routerLink]="item.routerLink"
                ></i>
    
                <div *ngFor="let child of (item.items || [])" class="extended-child">
                    <i class="mdi mdi-circle-medium"
                        [ngClass]="{
                            'pointer': child.routerLink,
                            'selected': child.selected
                        }"
                        [pTooltip]="child.label"
                        tooltipPosition="right"
                        [routerLink]="child.routerLink"
                    ></i>
                </div>
            </div>
        </div>
    </div>

    <div class="utilities-con">
        <div class="login-con">
            <login></login>
        </div>

        <div class="theme-switcher-con">
            <theme-switcher></theme-switcher>
        </div>
    </div>
</div>
