import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import Web3 from "web3";

import { BaseService } from "../shared/services";


@Injectable()
export class UserService extends BaseService {
    private web3: any

    constructor(
        http: HttpClient,
        private oAuthService: OAuthService
    ) {
        super(http);

        this.web3 = new Web3(window.ethereum);
    }

    static clearSessionStore(): void {
        sessionStorage.clear();
    }

    static get userId() {
        return sessionStorage.getItem("userId");
    }

    static set accessToken(token: string) {
        sessionStorage.setItem("access_token", token);
    }

    static get accessToken(): string {
        return sessionStorage.getItem("id_token");
    }

    static set refreshToken(tk) {
        if (tk) {
            sessionStorage.setItem("refresh_token", tk);
        } else {
            sessionStorage.removeItem("refresh_token");
            return;
        }
    }

    static get refreshToken(): string {
        return sessionStorage.getItem("refresh_token");
    }

    public async fetchCurrentAccount() {
        return await window.ethereum.request({
            method: 'eth_requestAccounts'
        }).then((accounts: string[]) => {
            // console.log("All accounts:", accounts);
            return accounts[0];

        }).catch((err: any) => {
            console.log("Account Fetch err:", err);
        });
    }

    extendSession() {
        return this.oAuthService
            .refreshToken()
            .then(info => {
                // console.log("Silent refresh success:", info);
            })
            .catch(err => {
                if (err.type !== "silent_refresh_timeout") {
                    // alert("Silent Refresh Error Occurred" + err);
                    console.log(
                        `Silent Refresh Error Occurred ${JSON.stringify(err)}`
                    );
                }
            });
    }

    getUser(): Observable<any> {
        return new Observable(sub => {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then((account: string[]) => {
                let userAccount = account[0];

                //get the balance of the account
                this.web3.eth.getBalance(userAccount).then((wei:number) => {
                    return sub.next({ username: userAccount, balance: wei});
                });
            });
        });
    }

    checkConnection(): Observable<any> {
        return new Observable(sub => {
            window.ethereum.request({ method: 'eth_accounts' }).then((account: string[]) => {
                let userAccount = account[0];
                if (userAccount) {
                    //get the balance of the account
                    this.web3.eth.getBalance(userAccount).then((wei: number) => {
                        return sub.next({ username: userAccount, balance: wei});
                    });

                } else {
                    return sub.next({username: "", balance: 0});
                }
            });
        })
    }

    updateCurrentChain(chainId: string): Observable<any> {
        return this.makeRequest({
            url: `api/updateCurrentChain?chainId=${chainId}`,
            method: BaseService.GET
        });
    }
}