import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { map, exhaustMap, catchError, mergeMap } from 'rxjs/operators';

import * as UserActions from './user.actions';
import { UserService } from './user.service';
import { EmitAlertAction } from '../shared/components/alerts/alerts.actions';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private userService: UserService 
    ) {}

    loginUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loginUserAction),
        exhaustMap(() => 
            this.userService.getUser().pipe(
                map((response) => {
                    return UserActions.loginUserSuccessAction(response)
                }),
                catchError(error => of(UserActions.loginUserFailAction()))
            )
        )
    ))

    checkConnection$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.checkUserConnectionAction),
        exhaustMap(() => 
            this.userService.checkConnection().pipe(
                map((response) => {
                    return UserActions.loginUserSuccessAction(response);
                }),
                catchError(error => of(UserActions.loginUserFailAction()))
            )
        )
    ))

    loginUserFail$ =  createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.loginUserFailAction),
            mergeMap(() => 
            from([
                    EmitAlertAction({
                        message: "Failed to login",
                        alert_type: "error"
                    })
                ])
            )
        );
    });

    setBlockchain$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.SetBlockChainAction),
        exhaustMap((action) =>
            this.userService.updateCurrentChain(action.payload).pipe(
                map(response => UserActions.SetBlockChainSuccessAction(response)),
                catchError(err => of(UserActions.SetBlockChainFailureAction(err)))
            )
        )
    ));
}