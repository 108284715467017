import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { AppStore } from 'src/app/store';
import { CloseAlertAction } from './alerts.actions';
import { getAlerts } from './alerts.selectors';
import { AlertModel } from './alerts.reducer';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  providers: [MessageService]
})
export class AlertsComponent implements OnInit {

  public alert_ids: number[] = [];
  constructor(private messageService: MessageService, private store: Store<AppStore>){}

  ngOnInit(): void {
    this.store.pipe(select(getAlerts)).subscribe(alerts => {
      const latest_alert: AlertModel = alerts[alerts.length - 1];
      
      if (latest_alert && !this.alert_ids.includes(latest_alert.id)) {
        //convert alert to message fields
        const message = {
          severity: latest_alert.alert_type,
          id: latest_alert.id,
          detail: latest_alert.message,
          sticky: latest_alert.sticky
        }
        this.messageService.add(message);
        this.alert_ids.push(latest_alert.id);
      }
    });
  }

  closed($event: any) {
    this.alert_ids = this.alert_ids.filter(id => id !== $event.message.id);
    this.store.dispatch(CloseAlertAction({ id: $event.message.id }));
  }

}
