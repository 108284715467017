import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { AppStore } from "../../../store";
import { RedeemItemModel, RewardsCardModel } from "../../../shared/models";
import { EmitAlertAction } from "../../../shared/components/alerts";
import { getRewardsCard, getCurrentPoints } from "../customer-loyalty.selectors";
import { RedeemItemAction } from "../customer-loyalty.actions";


@Component({
    selector: "redeem-rewards",
    templateUrl: "./redeem-rewards.component.html",
    styleUrls: ["./redeem-rewards.component.scss"]
})
export class RedeemRewardsComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private rewardsCard: RewardsCardModel;
    private currentPoints: number = 1000;

    public redeemItems: RedeemItemModel[] = [];
    
    constructor(
        private store: Store<AppStore>
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(getRewardsCard).subscribe(card => {
                this.rewardsCard = card;
            })
        );

        this.subscriptions.push(
            this.store.select(getCurrentPoints).subscribe(points => {
                this.currentPoints = points;
            })
        );

        this.redeemItems = [
            {
                "name": "Access to T-Mobile Events",
                "image": "assets/images/cards/special-event.jpg",
                "price": "$50",
                "points": 100,
                "type": "specials",
                "summary": "Customers access to attend T-Mobile-sponsored events, such as launch parties, product showcases, or tech conferences, where they can network with industry professionals and learn.",
                "summaryShort": "Access to launch parties, product showcases, or tech conferences",
                "policy": "Expires: Dec 2024"
            },  
            {
                "name": "T-Mobile Lounge Access",
                "image": "assets/images/cards/vip-lounge.jpg",
                "price": "$100",
                "points": 100,
                "type": "specials",
                "summary": "Enjoy exclusive access to T-Mobile-branded lounges at select venues, providing comfortable seating, charging stations, and other amenities during events or at partner locations.",
                "summaryShort": "T-Mobile-branded lounges at select venues, providing comfortable seating, charging stations",
                "policy": "Blackout Dates: Aug 1-15, Dec 15-31"
            },
            {
                "name": "VIP Concert Experiences",
                "image": "assets/images/cards/music-concert.jpg",
                "price": "$50",
                "points": 100,
                "type": "specials",
                "summary": "Gain access to exclusive seating, backstage tours, or meet and greets with popular music artists or bands at T-Mobile-sponsored concerts or events.",
                "summaryShort": "Exclusive seating, backstage tours, or meet and greets with popular music artists",
                "policy": "Expires: April 2024"
            },
            {
                "name": "Device Trade-In Programs",
                "image": "assets/images/cards/device-tradein.jpg",
                "price": "$200",
                "points": 100,
                "type": "specials",
                "summary": "Offer enhanced trade-in values or exclusive bonuses for customers looking to upgrade their devices, allowing them to unlock additional discounts or credits toward their new purchases.",
                "summaryShort": "Offer enhanced trade-in values or exclusive bonuses",
                "policy": "Current Phone: Active phone should be in good working condition"
            },
            {
                "name": "T-Mobile Rewards Token",
                "image": "assets/images/cards/rewards-token.jpg",
                "price": "$200",
                "points": 100,
                "type": "specials",
                "summary": "You can use this Token to unlock exciting rewards, discounts, and special perks within our ecosystem and coalition partners. ",
                "summaryShort": "You can use this Token to unlock exciting rewards, discounts, and special perks within our ecosystem and coalition partners.",
                "policy": "Usage: All coalition partners will redeem this token"
            },        
            {
                "name": "Starbucks Gift Card",
                "image": "assets/images/cards/card_starbucks.png",
                "price": "$5",
                "points": 100,
                "type": "gift-card"
            },
            {
                "name": "Safeway Card",
                "image": "assets/images/cards/card_safeway.png",
                "price": "$10",
                "points": 200,
                "type": "gift-card"
            },      
            {
                "name": "Amazon Card",
                "image": "assets/images/cards/card_amazon.png",
                "price": "$15",
                "points": 300,
                "type": "gift-card"
            }
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    redeemReward(item: RedeemItemModel) {
        // EXIT, if not sufficient points to burn
        if (item.points > this.currentPoints) {
            this.store.dispatch(EmitAlertAction({
                message: "You haven't earned enough points to redeem this item.",
                alert_type: "info"
            }));

            return;
        }

        if (!this.rewardsCard || !this.rewardsCard.tokenId) {
            this.store.dispatch(EmitAlertAction({
                message: "Please mint a Rewards Card first.",
                alert_type: "info"
            }));

            return;
        }

        this.store.dispatch(RedeemItemAction(item));
    }
}