<div class="submenu">
    <div *ngFor="let item of menuItems"
        class="menu-item"
        [routerLink]="item.routerLink"
    >
        {{ item.label }}
    </div>
</div>

<div class="loyalty-demo-con">
    <div class="left" [class.top-gap]="!hideCardSection">
        <router-outlet></router-outlet>
    </div>
    
    <div class="right" [class.collapsed]="hideCardSection">
        <p-panel>
            <ng-template pTemplate="header">
                <div class="header-title" (click)="toggleRewardsCard()">
                    <i class="mdi mdi-chevron-right"></i>
                    Rewards Card
                </div>
            </ng-template>

            <div class="rewards-card-con">
                <rewards-card></rewards-card>
            </div>

            <div>
                <rewards-tracker></rewards-tracker>
            </div>
        </p-panel>
    </div>

    <div class="collapsed-card"
        [class.shown]="hideCardSection"
        (click)="toggleRewardsCard()"
    >
        <i class="mdi mdi-chevron-left"></i>
        <img src="assets/images/demos/customer-loyalty/giftcard.png">
    </div>
</div>